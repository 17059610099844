import useOidcFetch from "@/hooks/useOidcFetch.js";
import { mapRawToStudent } from "@/mappers/student.js";
import type { JsonApiDocument } from "@/types/json-api.js";
import type { RawStudent, Student } from "@/types/student.js";
import { apiUrl } from "@/utils/api";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type CreateStudentValues = Omit<RawStudent, "id">;

export const useCreateStudentMutation = (): UseMutationResult<
    Student,
    Error,
    CreateStudentValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl("/students").toString(), {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to create student");
            }

            const raw = (await response.json()) as JsonApiDocument<RawStudent>;
            return mapRawToStudent(raw.data);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["students"] });
        },
    });
};

type DeleteStudentValues = Pick<RawStudent, "id">;

export const useDeleteStudentMutation = (): UseMutationResult<void, Error, DeleteStudentValues> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl(`/students/${values.id}`).toString(), {
                method: "DELETE",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to delete student");
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["students"] });
            await queryClient.invalidateQueries({ queryKey: ["carts"] });
        },
    });
};
