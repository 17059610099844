import Layout from "@/layout/index.js";
import Checkout, { CheckoutSuccess } from "@/pages/Checkout/index.js";
import Donate, { Success as DonateSuccess } from "@/pages/Donate/index.js";
import ErrorPage from "@/pages/ErrorPage";
import EventTicketSelector from "@/pages/EventTicketSelector/index.js";
import GiftCertificate, {
    Success as GiftCertificateSuccess,
} from "@/pages/GiftCertificate/index.js";
import Home from "@/pages/Home.js";
import PaymentProfiles from "@/pages/PaymentProfiles/index.js";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route errorElement={<ErrorPage />}>
                <Route index element={<Home />} />
                <Route path="events/:eventId" element={<EventTicketSelector />} />
                <Route path="checkout" element={<Checkout />} />
                <Route path="checkout/success/:cartId" element={<CheckoutSuccess />} />
                <Route path="donate" element={<Donate />} />
                <Route path="donate/success/:donationId" element={<DonateSuccess />} />
                <Route path="purchase-gift-certificate" element={<GiftCertificate />} />
                <Route
                    path="purchase-gift-certificate/success"
                    element={<GiftCertificateSuccess />}
                />
                <Route path="payment-profiles" element={<PaymentProfiles />} />
                <Route path="error" element={<ErrorPage />} />
            </Route>
        </Route>,
    ),
);
