import { useAuth0 } from "@auth0/auth0-react";
import { Container, LinearProgress, Link } from "@mui/material";
import type { ReactNode } from "react";
import { useEffect } from "react";

const Home = (): ReactNode => {
    const { isLoading } = useAuth0();

    useEffect(() => {
        if (!isLoading && import.meta.env.PROD) {
            window.location.href = "https://www.oldtownschool.org";
        }
    }, [isLoading]);

    if (isLoading || import.meta.env.PROD) {
        return <LinearProgress />;
    }

    return (
        <Container sx={{ py: 3 }}>
            You are running in development mode. Production will automatically redirect to{" "}
            <Link href="https://www.oldtownschool.org">https://www.oldtownschool.org</Link>.
        </Container>
    );
};

export default Home;
