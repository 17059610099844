import NumberField from "@/components/NumberField";
import { useAddToCartMutation, useUpdateGeneralAdmissionLineItemMutation } from "@/mutations/cart";
import { useEventQuery } from "@/queries/event";
import type { Cart, GeneralAdmissionEventLineItem } from "@/types/cart";
import { currencyFormatter } from "@/utils/format.js";
import { DateTimeFormatter } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Alert,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";

const dateTimeFormatter = DateTimeFormatter.ofPattern("EEEE, MMMM d, yyyy – h:mm a").withLocale(
    Locale.US,
);

type Props = {
    open: boolean;
    onClose: () => void;
    eventId: string;
    cart: Cart;
    lineItem?: GeneralAdmissionEventLineItem;
};

const GeneralAdmissionEventDialog = ({
    open,
    onClose,
    eventId,
    cart,
    lineItem,
}: Props): ReactNode => {
    const initialNumberOfTickets = lineItem?.numberOfTickets ?? 1;
    const [numberOfTickets, setNumberOfTickets] = useState(initialNumberOfTickets);
    const eventQuery = useEventQuery(eventId, { excludeLineItemId: lineItem?.id });
    const addToCartMutation = useAddToCartMutation(cart.id);
    const updateLineItemMutation = useUpdateGeneralAdmissionLineItemMutation(
        cart.id,
        lineItem?.id ?? "",
    );
    const { enqueueSnackbar } = useSnackbar();
    const [saleStarted, setSaleStarted] = useState(Boolean(lineItem));
    const [saleEnded, setSaleEnded] = useState(false);

    useEffect(() => {
        setNumberOfTickets(initialNumberOfTickets);
    }, [initialNumberOfTickets]);

    if (eventQuery.isPending) {
        return (
            <Backdrop open>
                <CircularProgress />
            </Backdrop>
        );
    }

    if (eventQuery.isError || eventQuery.data.type !== "general-admission") {
        return (
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
                <DialogContent>
                    <Alert severity="error">Failed to load event.</Alert>
                </DialogContent>
            </Dialog>
        );
    }

    const event = eventQuery.data;

    const handleSave = () => {
        if (lineItem) {
            updateLineItemMutation.mutate(
                { tickets: numberOfTickets },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Event has been updated", { variant: "success" });
                        onClose();
                    },
                    onError: () => {
                        enqueueSnackbar("Event could not be updated", { variant: "error" });
                        void eventQuery.refetch();
                    },
                },
            );
            return;
        }

        addToCartMutation.mutate(
            {
                type: "general-admission-event",
                eventId: eventId,
                numberOfTickets,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar("Event has been added to your cart", { variant: "success" });
                    onClose();
                },
                onError: () => {
                    enqueueSnackbar("Event could not be added to your cart", { variant: "error" });
                    void eventQuery.refetch();
                },
            },
        );
    };

    const saleStart = event.saleStart[cart.membershipRank !== null ? "member" : "general"];
    const saleOpen = saleStarted && !saleEnded;
    const ticketPrice = event.price[cart.membershipRank !== null ? "member" : "general"];
    const totalPrice = numberOfTickets * ticketPrice;
    const totalFee = numberOfTickets * event.fee;

    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogTitle>{event.title}</DialogTitle>
            <DialogContent dividers>
                <Typography>
                    {event.date.atTime(event.startTime).format(dateTimeFormatter)}
                </Typography>
                <Typography>
                    {event.location.name} - {event.location.room}
                </Typography>
                <Typography>
                    Price: {currencyFormatter.format(ticketPrice / 100)}
                    {event.fee > 0 && ` / Fee: ${currencyFormatter.format(event.fee / 100)}`}
                </Typography>

                {!saleStarted && (
                    <Alert severity="info" sx={{ mt: 2 }}>
                        The event isn't selling yet. Sale opens on{" "}
                        {dateTimeFormatter.format(saleStart.at)}.
                    </Alert>
                )}

                {saleEnded && (
                    <Alert severity="warning" sx={{ mt: 2 }}>
                        The event is not on sale anymore.
                    </Alert>
                )}

                {saleOpen &&
                    event.ticketsLeft !== null &&
                    event.maxTicketsPerBooking > event.ticketsLeft && (
                        <Alert severity="warning" sx={{ mt: 2 }}>
                            There{" "}
                            {event.ticketsLeft === 1
                                ? "is one ticket"
                                : `are ${event.ticketsLeft} tickets`}{" "}
                            remaining for this event.
                        </Alert>
                    )}

                {saleOpen && (event.ticketsLeft === null || event.ticketsLeft > 0) && (
                    <>
                        <NumberField
                            label="Number of tickets"
                            value={numberOfTickets}
                            minValue={1}
                            setValue={setNumberOfTickets}
                            maxValue={Math.min(
                                event.maxTicketsPerBooking,
                                event.ticketsLeft ?? event.maxTicketsPerBooking,
                            )}
                            sx={{ width: "100%", mt: 3 }}
                            disabled={!saleOpen}
                        />

                        <Typography sx={{ mt: 2 }}>
                            Total price: {currencyFormatter.format(totalPrice / 100)}
                            {totalFee > 0 && ` + ${currencyFormatter.format(totalFee / 100)} fee`}
                        </Typography>
                    </>
                )}

                {!lineItem && (
                    <>
                        <Countdown
                            date={saleStart.atLocal}
                            renderer={() => null}
                            onComplete={() => {
                                setSaleStarted(true);
                            }}
                        />
                        <Countdown
                            date={event.saleEnd.atLocal}
                            renderer={() => null}
                            onComplete={() => {
                                setSaleEnded(true);
                            }}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>

                <LoadingButton
                    loading={addToCartMutation.isPending || updateLineItemMutation.isPending}
                    onClick={handleSave}
                    disabled={!saleOpen || event.ticketsLeft === 0}
                >
                    {lineItem ? "Save" : "Add"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default GeneralAdmissionEventDialog;
