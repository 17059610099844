import { useSeatingChartContext } from "@/components/SeatingChart/SeatingChart.js";
import useAddSeatsToReservation from "@/hooks/useAddSeatsToReservation.js";
import type { ReactNode } from "react";
import { useCallback, useState } from "react";
import SeatSelector from "./SeatSelector.js";
import { determineAvailableRange, seatHeight, seatWidth, selectFromRange } from "./utils.js";

type Props = {
    x: number;
    y: number;
    table: string;
};

// This order is important as 1 and 4 are on opposite corners.
const seatNumbers = [1, 2, 4, 3];

const TableSelector = ({ x, y, table }: Props): ReactNode => {
    const { interactive, desiredTickets, event, reservation, onSelect } = useSeatingChartContext();
    const [sideSelected, setSideSelected] = useState<number[]>([]);
    const addSeatsToReservation = useAddSeatsToReservation();

    const handleMouseEnter = useCallback(
        (seatNumber: number) => {
            if (!interactive || desiredTickets === 1) {
                return;
            }

            const availableRange = determineAvailableRange(
                seatNumber,
                seatNumbers,
                `table${table}`,
                event,
                reservation,
                desiredTickets,
                true,
            );
            setSideSelected(selectFromRange(availableRange, desiredTickets));
        },
        [interactive, desiredTickets, table, event, reservation],
    );

    const handleMouseLeave = useCallback(() => {
        if (!interactive) {
            return;
        }

        setSideSelected([]);
    }, [interactive]);

    const handleClick = useCallback(
        async (seatNumber: number) => {
            if (!interactive) {
                return;
            }

            const availableRange = determineAvailableRange(
                seatNumber,
                seatNumbers,
                `table${table}`,
                event,
                reservation,
                desiredTickets,
                true,
            );
            const newSeats = [seatNumber, ...selectFromRange(availableRange, desiredTickets)];
            const numTotalSeats = await addSeatsToReservation(
                event.id,
                `table${table}`,
                newSeats,
                reservation,
            );
            setSideSelected([]);

            if (onSelect) {
                onSelect(numTotalSeats, newSeats.length);
            }
        },
        [interactive, desiredTickets, table, event, reservation, addSeatsToReservation, onSelect],
    );

    return (
        <g transform={`translate(${x}, ${y})`}>
            <SeatSelector
                x={0}
                y={seatHeight}
                row={`table${table}`}
                seatNumber={1}
                sideSelected={sideSelected.includes(1)}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <SeatSelector
                x={seatWidth}
                y={seatHeight}
                row={`table${table}`}
                seatNumber={2}
                sideSelected={sideSelected.includes(2)}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <SeatSelector
                x={0}
                y={0}
                row={`table${table}`}
                seatNumber={3}
                sideSelected={sideSelected.includes(3)}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <SeatSelector
                x={seatWidth}
                y={0}
                row={`table${table}`}
                seatNumber={4}
                sideSelected={sideSelected.includes(4)}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
        </g>
    );
};

export default TableSelector;
