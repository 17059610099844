import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import type { SxProps, Theme } from "@mui/material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect } from "react";
import type { ReactNode } from "react";

type Props = {
    label: string;
    value: number | null;
    setValue: (value: number) => void;
    minValue: number;
    maxValue: number;
    helperText?: string;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    required?: boolean;
    errorMessage?: string;
};

const NumberField = ({
    label,
    value,
    setValue,
    minValue,
    maxValue,
    helperText,
    sx,
    disabled,
    required,
    errorMessage,
}: Props): ReactNode => {
    useEffect(() => {
        if (value !== null && value < minValue) {
            setValue(minValue);
        }

        if (value !== null && value > maxValue) {
            setValue(maxValue);
        }
    }, [value, setValue, minValue, maxValue]);

    return (
        <TextField
            label={label}
            value={value !== null ? value.toString() : ""}
            disabled={disabled}
            required={required}
            error={Boolean(errorMessage)}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            disabled={value === null || value === minValue || disabled}
                            onClick={() => {
                                if (value !== null) {
                                    setValue(Math.max(minValue, value - 1));
                                }
                            }}
                        >
                            <RemoveIcon />
                        </IconButton>
                        <IconButton
                            disabled={value === maxValue || disabled}
                            onClick={() => {
                                setValue(Math.min(maxValue, value === null ? minValue : value + 1));
                            }}
                            edge="end"
                        >
                            <AddIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            helperText={errorMessage ?? helperText}
            sx={sx}
        />
    );
};

export default NumberField;
