import useMembership from "@/hooks/useMembership.js";
import PresaleCodeForm from "@/pages/EventTicketSelector/PresaleCodeForm.js";
import { useEventQuery } from "@/queries/event.js";
import type { SeatMeta } from "@/types/event.js";
import { currencyFormatter } from "@/utils/format.js";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTimeFormatter } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import { Alert, Box, Container, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import type { ReactNode } from "react";
import Countdown from "react-countdown";
import { useParams, useSearchParams } from "react-router-dom";
import ReservedSelector from "./ReservedSelector/index.js";

type Params = {
    eventId: string;
};

const dateTimeFormatter = DateTimeFormatter.ofPattern("EEEE, MMMM d, yyyy – h:mm a").withLocale(
    Locale.US,
);

type PriceRange = {
    min: number;
    max: number;
};

const renderPriceRange = (range: PriceRange): string => {
    if (range.min === range.max) {
        return currencyFormatter.format(range.min / 100);
    }

    return `${currencyFormatter.format(range.min / 100)} - ${currencyFormatter.format(range.max / 100)}`;
};

const EventTicketSelector = (): ReactNode => {
    const { eventId } = useParams<Params>();
    const [searchParams] = useSearchParams();
    const { user } = useAuth0();
    const membership = useMembership();
    const [saleStarted, setSaleStarted] = useState(false);
    const [saleEnded, setSaleEnded] = useState(false);

    if (!eventId) {
        throw new Error("Event ID missing");
    }

    const reservationId = searchParams.get("reservationId");
    const eventQuery = useEventQuery(eventId, { excludeReservationId: reservationId ?? undefined });

    if (eventQuery.isError || (eventQuery.data && eventQuery.data.type !== "reserved")) {
        return (
            <Container sx={{ py: 3 }}>
                <Alert severity="error">Could not find event.</Alert>
            </Container>
        );
    }

    if (!eventQuery.data) {
        return <LinearProgress />;
    }

    const event = eventQuery.data;
    const saleStart = event.saleStart[membership ? "member" : "general"];

    const seatPrices = [...event.seats.values()]
        .filter((seat): seat is SeatMeta & Required<Pick<SeatMeta, "price">> => Boolean(seat.price))
        .map((seat) => seat.price);

    const generalPrices = [event.price.general, ...seatPrices.map((price) => price.general)];
    const memberPrices = [event.price.member, ...seatPrices.map((price) => price.member)];

    const priceRanges = {
        general: {
            min: Math.min(...generalPrices),
            max: Math.max(...generalPrices),
        },
        members: {
            min: Math.min(...memberPrices),
            max: Math.max(...memberPrices),
        },
    };

    const saleOpen = (saleStarted || reservationId) && !saleEnded;

    return (
        <>
            <Box sx={{ p: 3, backgroundColor: "primary.main", color: "primary.contrastText" }}>
                <Typography variant="h5">{event.title}</Typography>
                <Typography>
                    {event.date.atTime(event.startTime).format(dateTimeFormatter)}
                </Typography>
                <Typography>
                    {event.location.name} - {event.location.room}
                </Typography>
                <Typography>
                    General: {renderPriceRange(priceRanges.general)}
                    {" / "}
                    Members: {renderPriceRange(priceRanges.members)}
                    {event.fee > 0 && ` / Fee: ${currencyFormatter.format(event.fee / 100)}`}
                </Typography>
            </Box>

            <Container sx={{ py: 3 }}>
                {!(saleStarted || reservationId) && (
                    <>
                        <Alert severity="info">
                            The event isn't selling yet. Sale opens on{" "}
                            {dateTimeFormatter.format(saleStart.at)}.
                        </Alert>

                        {user && !membership && <PresaleCodeForm event={event} />}
                    </>
                )}

                {saleEnded && <Alert severity="warning">The event is not on sale anymore.</Alert>}

                {saleOpen && <ReservedSelector event={event} />}

                <Countdown
                    date={saleStart.atLocal}
                    renderer={() => null}
                    onComplete={() => {
                        setSaleStarted(true);
                    }}
                />
                <Countdown
                    date={event.saleEnd.atLocal}
                    renderer={() => null}
                    onComplete={() => {
                        setSaleEnded(true);
                    }}
                />
            </Container>
        </>
    );
};

export default EventTicketSelector;
