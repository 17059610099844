import ConfirmDialog from "@/components/ConfirmDialog/index.js";
import DialogController from "@/components/DialogController/index.js";
import {
    useDeletePaymentProfileCardMutation,
    useSetDefaultPaymentProfileMutation,
    useUpdatePaymentProfileMutation,
} from "@/mutations/payment-profile.js";
import CardDialog from "@/pages/PaymentProfiles/CardDialog.js";
import type { PaymentProfile } from "@/types/payment-profile.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    Backdrop,
    Checkbox,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import type { ReactNode } from "react";
import { useConfirm } from "react-confirm-hook";

type Props = {
    paymentProfile: PaymentProfile;
};

const PaymentProfilesListItem = ({ paymentProfile }: Props): ReactNode => {
    const setDefaultPaymentProfileMutation = useSetDefaultPaymentProfileMutation();
    const updatePaymentProfileMutation = useUpdatePaymentProfileMutation();
    const deletePaymentProfileMutation = useDeletePaymentProfileCardMutation();
    const popupState = usePopupState({
        variant: "popover",
        popupId: `pp-options-${paymentProfile.id}`,
    });
    const confirm = useConfirm(ConfirmDialog);

    const handleDelete = () => {
        popupState.close();
        confirm({
            title: "Delete payment profile",
            message: (
                <>
                    You are about to delete the payment profile &quot;{paymentProfile.cardNumber}
                    &quot;. If you proceed, this card cannot be used in recurring charges anymore.
                </>
            ),
            onConfirm: async () => {
                await deletePaymentProfileMutation.mutateAsync({ id: paymentProfile.id });
            },
        });
    };

    return (
        <ListItem
            disablePadding
            secondaryAction={
                <>
                    <IconButton {...bindTrigger(popupState)} edge="end">
                        <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        <DialogController
                            renderDialog={(props) => (
                                <CardDialog
                                    onSubmit={async (opaqueData) => {
                                        await updatePaymentProfileMutation.mutateAsync({
                                            id: paymentProfile.id,
                                            opaqueAuthorizeNetData: opaqueData,
                                        });
                                        props.onClose();
                                    }}
                                    onClose={() => {
                                        popupState.close();
                                        props.onClose();
                                    }}
                                    open={props.open}
                                />
                            )}
                            renderButton={(props) => (
                                <MenuItem
                                    onClick={() => {
                                        // calling .close() here closes dialog bc it's rendered inside the popupmenu
                                        // popupState.close();
                                        props.onClick();
                                    }}
                                >
                                    Edit
                                </MenuItem>
                            )}
                        />
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                </>
            }
        >
            <ListItemButton
                onClick={() => {
                    setDefaultPaymentProfileMutation.mutate({ id: paymentProfile.id });
                }}
            >
                <ListItemIcon>
                    <Checkbox checked={paymentProfile.isDefault} edge="start" />
                </ListItemIcon>

                <ListItemText
                    primary={`${paymentProfile.cardNumber} (${paymentProfile.cardType})`}
                    secondary={`Expires ${paymentProfile.expirationDate}`}
                />
            </ListItemButton>

            <Backdrop open={setDefaultPaymentProfileMutation.isPending}>
                <CircularProgress />
            </Backdrop>
        </ListItem>
    );
};

export default PaymentProfilesListItem;
