import { useSeatingChartContext } from "@/components/SeatingChart/SeatingChart.js";
import useAddSeatsToReservation from "@/hooks/useAddSeatsToReservation.js";
import type { SeatRow } from "@/seating-layouts/index.js";
import type { ReactNode } from "react";
import { useCallback, useMemo, useState } from "react";
import SeatSelector from "./SeatSelector.js";
import { determineAvailableRange, seatPadding, seatWidth, selectFromRange } from "./utils.js";

type Props = {
    x: number;
    y: number;
    row: SeatRow;
};

const SeatRowSelector = ({ x, y, row }: Props): ReactNode => {
    const { interactive, desiredTickets, event, reservation, onSelect } = useSeatingChartContext();
    const [sideSelected, setSideSelected] = useState<number[]>([]);
    const addSeatsToReservation = useAddSeatsToReservation();

    const seatNumbers = useMemo(() => {
        const start = Math.min(row.startNumber, row.endNumber);
        const end = Math.max(row.startNumber, row.endNumber);
        const seatNumbers = Array.from(
            { length: end - start + 1 },
            (_value, index) => index + start,
        );

        if (row.endNumber < row.startNumber) {
            seatNumbers.reverse();
        }

        return seatNumbers;
    }, [row.startNumber, row.endNumber]);

    const handleMouseEnter = useCallback(
        (seatNumber: number) => {
            if (!interactive || desiredTickets === 1) {
                return;
            }

            const availableRange = determineAvailableRange(
                seatNumber,
                seatNumbers,
                row.name,
                event,
                reservation,
                desiredTickets,
            );
            setSideSelected(selectFromRange(availableRange, desiredTickets));
        },
        [interactive, seatNumbers, desiredTickets, row, event, reservation],
    );

    const handleMouseLeave = useCallback(() => {
        if (!interactive) {
            return;
        }

        setSideSelected([]);
    }, [interactive]);

    const handleClick = useCallback(
        async (seatNumber: number) => {
            if (!interactive) {
                return;
            }

            const availableRange = determineAvailableRange(
                seatNumber,
                seatNumbers,
                row.name,
                event,
                reservation,
                desiredTickets,
            );
            const newSeats = [seatNumber, ...selectFromRange(availableRange, desiredTickets)];
            const numTotalSeats = await addSeatsToReservation(
                event.id,
                row.name,
                newSeats,
                reservation,
            );
            setSideSelected([]);

            if (onSelect) {
                onSelect(numTotalSeats, newSeats.length);
            }
        },
        [
            interactive,
            seatNumbers,
            desiredTickets,
            row,
            event,
            reservation,
            addSeatsToReservation,
            onSelect,
        ],
    );

    return (
        <g transform={`translate(${x}, ${y})`}>
            {seatNumbers.map((seatNumber, index) => (
                <SeatSelector
                    key={seatNumber}
                    x={index * seatWidth}
                    y={seatPadding}
                    row={row.name}
                    seatNumber={seatNumber}
                    sideSelected={sideSelected.includes(seatNumber)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                />
            ))}
        </g>
    );
};

export default SeatRowSelector;
