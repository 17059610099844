import useOidcFetch from "@/hooks/useOidcFetch.js";
import { mapRawToReservation } from "@/mappers/reservation.js";
import type { JsonApiDocument, JsonApiError } from "@/types/json-api.js";
import type { RawReservation, Reservation, Seat } from "@/types/reservation.js";
import { apiUrl } from "@/utils/api.js";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";

export class ReservationConflictError extends Error {}
export class ValidationError extends Error {}

const useHandleError = () => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [, setSearchParams] = useSearchParams();

    return async (error: Error) => {
        if (error instanceof ReservationConflictError) {
            enqueueSnackbar("One of the selected seats is already reserved", { variant: "error" });
            await queryClient.invalidateQueries({ queryKey: ["events"] });
            return;
        }

        if (error instanceof ValidationError) {
            enqueueSnackbar(error.message, { variant: "error" });
            await queryClient.invalidateQueries({ queryKey: ["reservations"] });
            return;
        }

        enqueueSnackbar("An unknown error occurred, please try again", { variant: "error" });
        setSearchParams({});

        await Promise.all([
            queryClient.invalidateQueries({ queryKey: ["events"] }),
            queryClient.invalidateQueries({ queryKey: ["reservations"] }),
        ]);
    };
};

export type CreateReservationValues = {
    eventId: string;
    seats?: Seat[];
    presaleCode?: string;
};

export const useCreateReservationMutation = (): UseMutationResult<
    Reservation,
    Error,
    CreateReservationValues
> => {
    const queryClient = useQueryClient();
    const [, setSearchParams] = useSearchParams();
    const handleError = useHandleError();
    const oidcFetch = useOidcFetch();

    return useMutation({
        mutationFn: async (values: CreateReservationValues) => {
            const fetch = values.presaleCode ? oidcFetch : window.fetch;
            const response = await fetch(apiUrl("/reservations").toString(), {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 409) {
                throw new ReservationConflictError("Failed to acquire reservation");
            }

            if (response.status === 400) {
                const data = (await response.json()) as JsonApiError;
                throw new ValidationError(data.message);
            }

            if (!response.ok) {
                throw new Error("Failed to create reservation");
            }

            const rawReservation = (await response.json()) as JsonApiDocument<RawReservation>;
            return mapRawToReservation(rawReservation.data);
        },
        onSuccess: (reservation) => {
            queryClient.setQueryData(["reservations", reservation.id], reservation);
            queryClient.setQueryData(
                ["events", reservation.eventId, reservation.id],
                queryClient.getQueryData(["events", reservation.eventId, null]),
            );
            setSearchParams({ reservationId: reservation.id });
        },
        onError: handleError,
    });
};

export type UpdateReservationValues = {
    reservationId: string;
    seats: Seat[];
};

export const useUpdateReservationMutation = (): UseMutationResult<
    Reservation,
    Error,
    UpdateReservationValues
> => {
    const queryClient = useQueryClient();
    const handleError = useHandleError();

    return useMutation({
        mutationFn: async (values: UpdateReservationValues) => {
            const response = await fetch(
                apiUrl(`/reservations/${values.reservationId}`).toString(),
                {
                    method: "PATCH",
                    body: JSON.stringify(values),
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            if (response.status === 409) {
                throw new ReservationConflictError("Failed to acquire reservation");
            }

            if (response.status === 400) {
                const data = (await response.json()) as JsonApiError;
                throw new ValidationError(data.message);
            }

            if (!response.ok) {
                throw new Error("Failed to update reservation");
            }

            const rawReservation = (await response.json()) as JsonApiDocument<RawReservation>;
            return mapRawToReservation(rawReservation.data);
        },
        onSuccess: async (reservation) => {
            queryClient.setQueryData(["reservations", reservation.id], reservation);
            await queryClient.invalidateQueries({ queryKey: ["carts"] });
        },
        onError: handleError,
    });
};

export type UpdateHandicappedValues = {
    numberOfHandicapped: number;
    numberOfWheelchairs: number;
};

export const useUpdateHandicappedMutation = (
    reservationId: string,
): UseMutationResult<Reservation, Error, UpdateHandicappedValues> => {
    const queryClient = useQueryClient();
    const handleError = useHandleError();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/reservations/${reservationId}/handicapped`).toString(),
                {
                    method: "PATCH",
                    body: JSON.stringify(values),
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            if (response.status === 409) {
                throw new ReservationConflictError("Failed to acquire reservation");
            }

            if (!response.ok) {
                throw new Error("Failed to update reservation");
            }

            const rawReservation = (await response.json()) as JsonApiDocument<RawReservation>;
            return mapRawToReservation(rawReservation.data);
        },
        onSuccess: async (reservation) => {
            queryClient.setQueryData(["reservations", reservation.id], reservation);
            await queryClient.invalidateQueries({ queryKey: ["carts"] });
        },
        onError: handleError,
    });
};

export type DeleteReservationValues = {
    reservationId: string;
};

export const useDeleteReservationMutation = (): UseMutationResult<
    void,
    Error,
    DeleteReservationValues
> => {
    const queryClient = useQueryClient();
    const [, setSearchParams] = useSearchParams();

    return useMutation({
        mutationFn: async (values: DeleteReservationValues) => {
            const response = await fetch(
                apiUrl(`/reservations/${values.reservationId}`).toString(),
                {
                    method: "DELETE",
                },
            );

            if (!response.ok) {
                throw new Error("Failed to delete reservation");
            }
        },
        onSettled: async () => {
            setSearchParams({});
            await queryClient.invalidateQueries({ queryKey: ["events"] });
            await queryClient.invalidateQueries({ queryKey: ["carts"] });
        },
    });
};
