import { Alert, List, ListItem, ListItemText } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    upcomingCharges: string[];
};

const UpcomingChargesList = ({ upcomingCharges }: Props): ReactNode => {
    if (upcomingCharges.length === 0) {
        return <Alert severity="info">You have no upcoming charges.</Alert>;
    }

    return (
        <List disablePadding>
            {upcomingCharges.map((charge) => (
                <ListItem key={charge} disableGutters disablePadding>
                    <ListItemText>{charge}</ListItemText>
                </ListItem>
            ))}
        </List>
    );
};

export default UpcomingChargesList;
