import ExpandMore from "@/components/ExpandMore/index.js";
import type { Cart, CourseClassLineItem, EventLineItem } from "@/types/cart.js";
import ClassIcon from "@mui/icons-material/Class";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore.js";
import { Box, Card, CardHeader, Chip, Collapse, Divider, Stack, Tab, Tabs } from "@mui/material";
import Typography from "@mui/material/Typography";
import type { ReactNode } from "react";
import { useMemo } from "react";
import CourseClasses from "./CourseClasses.js";
import Events from "./Events.js";

export type DetailsTabValue = "classes" | "events";

type FilterItemsReturn<T extends DetailsTabValue> = T extends "classes"
    ? CourseClassLineItem[]
    : EventLineItem[];

const filterItems = <T extends DetailsTabValue>(cart: Cart, activeTab: T): FilterItemsReturn<T> => {
    return cart.lineItems.filter(
        (item) =>
            (activeTab === "classes" && item.type === "course-class") ||
            (activeTab !== "classes" && item.type !== "course-class"),
    ) as FilterItemsReturn<T>;
};

type Props = {
    cart: Cart;
    open: boolean;
    setOpen: (open: boolean) => void;
    activeTab: DetailsTabValue;
    setActiveTab: (value: DetailsTabValue) => void;
};

const Details = ({ cart, activeTab, setActiveTab, open, setOpen }: Props): ReactNode => {
    const items = useMemo(() => filterItems(cart, activeTab), [cart, activeTab]);
    const numClasses = filterItems(cart, "classes").length;
    const numEvents = filterItems(cart, "events").length;

    return (
        <Card>
            <CardHeader
                disableTypography
                title={
                    <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="h5">Details</Typography>

                        <Chip
                            variant="outlined"
                            icon={<ClassIcon />}
                            label={numClasses}
                            onClick={() => {
                                setOpen(true);
                                setActiveTab("classes");
                            }}
                            clickable
                        />

                        <Chip
                            variant="outlined"
                            icon={<ConfirmationNumberIcon />}
                            label={numEvents}
                            onClick={() => {
                                setOpen(true);
                                setActiveTab("events");
                            }}
                            clickable
                        />
                    </Stack>
                }
                action={
                    <ExpandMore
                        expand={open}
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                }
            />
            <Collapse in={open}>
                <Tabs
                    variant="fullWidth"
                    value={activeTab}
                    onChange={(_event, value: DetailsTabValue) => {
                        setActiveTab(value);
                    }}
                >
                    <Tab
                        icon={<ClassIcon />}
                        iconPosition="start"
                        label="Classes"
                        value="classes"
                    />
                    <Tab
                        icon={<ConfirmationNumberIcon />}
                        iconPosition="start"
                        label="Events"
                        value="events"
                    />
                </Tabs>

                <Divider />

                <Box>
                    {activeTab === "classes" && (
                        <CourseClasses cart={cart} items={items as CourseClassLineItem[]} />
                    )}
                    {activeTab === "events" && (
                        <Events cart={cart} items={items as EventLineItem[]} />
                    )}
                </Box>
            </Collapse>
        </Card>
    );
};

export default Details;
