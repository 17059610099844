import useOidcFetch from "@/hooks/useOidcFetch.js";
import type { CustomerDonation } from "@/types/donation";
import { apiUrl } from "@/utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

export const useCreateDonationMutation = (): UseMutationResult<void, Error, CustomerDonation> => {
    const oidcFetch = useOidcFetch();
    const { isAuthenticated } = useAuth0();

    return useMutation({
        mutationFn: async (values: CustomerDonation) => {
            const fetch = isAuthenticated ? oidcFetch : window.fetch;
            const response = await fetch(apiUrl("/donations").toString(), {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to create donation");
            }
        },
    });
};
