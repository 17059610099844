import type { Cart } from "@/types/cart.js";
import type { Price } from "@/types/event.js";
import type { Student } from "@/types/student.js";
import { LocalDate } from "@js-joda/core";

export const getClassPriceCategory = (cart: Cart, student?: Student | null): keyof Price => {
    if (cart.membershipRank === null) {
        return "general";
    }

    if (!student) {
        return "member";
    }

    if (cart.membershipRank < 3 && student.dateOfBirth.isAfter(LocalDate.now().minusYears(18))) {
        return "general";
    }

    return "member";
};
