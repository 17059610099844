import { useCallback } from "react";
import type { AuthData, CardData, DispatchDataResponse } from "react-acceptjs";
import { useAcceptJs } from "react-acceptjs";

const authData: AuthData = {
    apiLoginID: import.meta.env.VITE_APP_ACCEPTJS_LOGIN_ID,
    clientKey: import.meta.env.VITE_APP_ACCEPTJS_CLIENT_KEY,
};

const environment = import.meta.env.VITE_APP_ACCEPTJS_MODE;

type AcceptJsDispatcher = (cardData: CardData) => Promise<DispatchDataResponse>;

export class AcceptJsError extends Error {}

const useDispatchAcceptJs = (): AcceptJsDispatcher => {
    const { dispatchData } = useAcceptJs({ environment, authData });

    return useCallback(
        async (cardData: CardData) => {
            return await dispatchData({ cardData }).catch(async (error: DispatchDataResponse) => {
                return Promise.reject(new AcceptJsError(error.messages.message[0].text));
            });
        },
        [dispatchData],
    );
};

export default useDispatchAcceptJs;
