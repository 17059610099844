import { cabaretLayout, fullLayout } from "@/seating-layouts/layouts.js";
import type { FloorName, LayoutName, SeatingLayout } from "./types.js";

export * from "./types.js";

export const getLayout = (layoutName: LayoutName): SeatingLayout => {
    switch (layoutName) {
        case "full":
            return fullLayout;

        case "cabaret":
            return cabaretLayout;
    }
};

export const floorLabel = (floorName: FloorName): string => {
    switch (floorName) {
        case "main":
            return "Main Floor";

        case "balcony":
            return "Balcony";
    }
};
