import type { GiftCertificate } from "@/types/gift-certificate.js";
import { apiUrl } from "@/utils/api";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

export const useCreateGiftCertificateMutation = (): UseMutationResult<
    void,
    Error,
    GiftCertificate
> => {
    return useMutation({
        mutationFn: async (values: GiftCertificate) => {
            const response = await fetch(apiUrl("/gift").toString(), {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to create gift certificate purchase");
            }
        },
    });
};
