import NumberField from "@/components/NumberField";
import { checkoutReservationIdKey } from "@/pages/Checkout/AddReservation";
import AccessibleSelector from "@/pages/EventTicketSelector/ReservedSelector/AccessibleSelector";
import { useReservationQuery } from "@/queries/reservation.js";
import type { ReservedEvent } from "@/types/event.js";
import { seatKey } from "@/utils/reservation";
import AccessibleIcon from "@mui/icons-material/Accessible";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Paper,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import type { ReactNode } from "react";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReservationPanel from "./ReservationPanel.js";
import SeatFinderDialog from "./SeatFinderDialog.js";
import SeatingChartContainer from "./SeatingChartContainer.js";

type Props = {
    event: ReservedEvent;
};

const ReservedSelector = ({ event }: Props): ReactNode => {
    const [numberOfTickets, setNumberOfTickets] = useState(1);
    const [seatFinderDialogOpen, setSeatFinderDialogOpen] = useState(false);
    const [showMemberPrices, setShowMemberPrices] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();

    const reservationId = searchParams.get("reservationId");
    const reservationQuery = useReservationQuery(reservationId);
    const reservedSeats = reservationQuery.data?.seats.size ?? 0;
    let desiredTickets = numberOfTickets - reservedSeats;
    const [numberOfTicketsDialogOpen, setNumberOfTicketsDialogOpen] = useState(!reservationId);
    const [accessibleValidated, setAccessibleValidated] = useState(false);

    if (desiredTickets === 0 && numberOfTickets < event.maxTicketsPerBooking) {
        desiredTickets = 1;
    }

    useEffect(() => {
        if (reservationId && !reservationQuery.isLoading && !reservationQuery.data) {
            setSearchParams({});
        }
    }, [reservationId, reservationQuery, setSearchParams]);

    useLayoutEffect(() => {
        if (reservedSeats > numberOfTickets) {
            setNumberOfTickets(reservedSeats);
        }
    }, [numberOfTickets, reservedSeats]);

    const hasAccessibleSeatSelected = useMemo(() => {
        if (!reservationQuery.data) {
            return false;
        }

        for (const seat of reservationQuery.data.seats.values()) {
            if (event.seats.get(seatKey(seat))?.accessible) {
                return true;
            }
        }

        return false;
    }, [reservationQuery.data, event.seats]);

    return (
        <>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                alignItems={{ xs: "stretch", sm: "center" }}
            >
                <NumberField
                    label="Number of tickets"
                    value={numberOfTickets}
                    setValue={setNumberOfTickets}
                    minValue={reservedSeats}
                    maxValue={event.maxTicketsPerBooking}
                    sx={{ width: { xs: "100%", sm: 200 }, mr: "auto" }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showMemberPrices}
                            onChange={(event) => {
                                setShowMemberPrices(event.target.checked);
                            }}
                            edge="start"
                        />
                    }
                    label="Show member prices"
                />
                <Button
                    variant="outlined"
                    onClick={() => {
                        setSeatFinderDialogOpen(true);
                    }}
                >
                    Select seats from list
                </Button>
                <SeatFinderDialog
                    open={seatFinderDialogOpen}
                    onClose={() => {
                        setSeatFinderDialogOpen(false);
                    }}
                    event={event}
                    priceCategory={showMemberPrices ? "member" : "general"}
                    reservation={reservationQuery.data}
                />
            </Stack>

            {reservationQuery.data && (
                <ReservationPanel
                    event={event}
                    reservation={reservationQuery.data}
                    showMemberPrices={showMemberPrices}
                />
            )}

            {hasAccessibleSeatSelected && reservationQuery.data && (
                <AccessibleSelector
                    reservation={reservationQuery.data}
                    validated={accessibleValidated}
                />
            )}

            <Button
                variant="contained"
                sx={{ mt: 2 }}
                disabled={numberOfTickets > reservedSeats || !reservationId}
                fullWidth={mobileView}
                onClick={() => {
                    if (!reservationId) {
                        return;
                    }

                    if (
                        hasAccessibleSeatSelected &&
                        (reservationQuery.data?.numberOfHandicapped === null ||
                            reservationQuery.data?.numberOfWheelchairs === null)
                    ) {
                        setAccessibleValidated(true);
                        return;
                    }

                    window.sessionStorage.setItem(checkoutReservationIdKey, reservationId);
                    navigate({
                        pathname: "/checkout",
                        search: `?${new URLSearchParams([
                            ["reservationId", reservationId],
                        ]).toString()}`,
                    });
                }}
            >
                Proceed to checkout
            </Button>

            {numberOfTickets > reservedSeats && (
                <Alert severity="info" sx={{ mt: 2 }}>
                    Select your seats from the chart below. Alternatively you can find seats based
                    on price or preference with the button above.
                </Alert>
            )}

            <Paper sx={{ p: 2, mt: 2 }} variant="outlined">
                <SeatingChartContainer
                    desiredTickets={desiredTickets}
                    event={event}
                    reservation={reservationQuery.data}
                />
            </Paper>

            <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
                <Typography sx={{ mb: 1 }}>Legend</Typography>

                <Grid container spacing={2}>
                    <Grid>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box
                                sx={{
                                    backgroundColor: "primary.main",
                                    borderRadius: 1,
                                    width: 24,
                                    height: 24,
                                }}
                            />
                            <Typography variant="body2">Available</Typography>
                        </Stack>
                    </Grid>
                    <Grid>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box
                                sx={{
                                    backgroundColor: "primary.main",
                                    borderRadius: 1,
                                    width: 24,
                                    height: 24,
                                    color: "#ffffff",
                                }}
                            >
                                <AccessibleIcon />
                            </Box>
                            <Typography variant="body2">Accessible</Typography>
                        </Stack>
                    </Grid>
                    <Grid>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box
                                sx={{
                                    backgroundColor: "grey.500",
                                    borderRadius: 1,
                                    width: 24,
                                    height: 24,
                                }}
                            />
                            <Typography variant="body2">Reserved</Typography>
                        </Stack>
                    </Grid>
                    <Grid>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box
                                sx={{
                                    backgroundColor: "secondary.main",
                                    borderRadius: 1,
                                    width: 24,
                                    height: 24,
                                }}
                            />
                            <Typography variant="body2">Selected</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>

            <Dialog open={numberOfTicketsDialogOpen} maxWidth="xs" fullWidth>
                <DialogTitle>Select tickets</DialogTitle>
                <DialogContent dividers>
                    <Typography>
                        Please select the number of tickets you wish to purchase. Then use the seat
                        map picker to select your seat positions.
                    </Typography>

                    <Divider sx={{ my: 2 }} />

                    <NumberField
                        label="Number of tickets"
                        value={numberOfTickets}
                        setValue={setNumberOfTickets}
                        minValue={reservedSeats}
                        maxValue={event.maxTicketsPerBooking}
                        sx={{ width: "100%" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setNumberOfTicketsDialogOpen(false);
                        }}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ReservedSelector;
