import DialogController from "@/components/DialogController/index.js";
import { useCreatePaymentProfileMutation } from "@/mutations/payment-profile.js";
import { usePaymentProfilesQuery } from "@/queries/payment-profile.js";
import { useUpcomingChargesQuery } from "@/queries/upcoming-charge.js";
import { Alert, Box, Button, Container, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import type { ReactNode } from "react";
import CardDialog from "./CardDialog.js";
import PaymentProfilesList from "./PaymentProfilesList.js";
import UpcomingChargesList from "./UpcomingChargesList.js";

const PaymentProfiles = (): ReactNode => {
    const createPaymentProfileMutation = useCreatePaymentProfileMutation();
    const paymentProfilesQuery = usePaymentProfilesQuery();
    const upcomingChargesQuery = useUpcomingChargesQuery();

    return (
        <Container sx={{ py: 3 }} maxWidth="sm">
            <Box display="flex" mb={4}>
                <Typography variant="h5" sx={{ mr: "auto" }}>
                    Payment Profiles
                </Typography>

                <DialogController
                    renderDialog={(props) => (
                        <CardDialog
                            onSubmit={async (opaqueData) => {
                                await createPaymentProfileMutation.mutateAsync({
                                    opaqueAuthorizeNetData: opaqueData,
                                });
                                props.onClose();
                            }}
                            {...props}
                        />
                    )}
                    renderButton={(props) => (
                        <Button variant="contained" {...props}>
                            Add card
                        </Button>
                    )}
                />
            </Box>

            <Typography paragraph>
                On this page you can create new payment profiles as well as update existing profiles
                with new card information.
            </Typography>

            <Typography paragraph>
                You can also select a default payment profile, which will be pre-selected for you
                during checkout.
            </Typography>

            <Alert severity="info" sx={{ mb: 2 }}>
                Select your default payment profile by ticking the checkbox next to it.
            </Alert>

            {!paymentProfilesQuery.data ? (
                <LinearProgress />
            ) : (
                <PaymentProfilesList paymentProfiles={paymentProfilesQuery.data} />
            )}

            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Upcoming Charges
            </Typography>

            {!upcomingChargesQuery.data ? (
                <LinearProgress />
            ) : (
                <UpcomingChargesList upcomingCharges={upcomingChargesQuery.data} />
            )}
        </Container>
    );
};

export default PaymentProfiles;
