import { serverZone } from "@/utils/datetime.js";
import type { User } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { LocalDate } from "@js-joda/core";
import { useMemo } from "react";

export type Membership = {
    expiresOn: LocalDate;
};

type IdTokenMembership = {
    expiresOn: string;
};

const idTokenMembershipKey = "https://oldtownschool.org/membership";

const useMembership = (): Membership | null => {
    const { user } = useAuth0<User & { [idTokenMembershipKey]?: IdTokenMembership }>();

    return useMemo(() => {
        if (!user) {
            return null;
        }

        const idTokenMembership = user["https://oldtownschool.org/membership"];

        if (!idTokenMembership) {
            return null;
        }

        const expiresOn = LocalDate.parse(idTokenMembership.expiresOn);
        const today = LocalDate.now(serverZone);

        if (expiresOn.isBefore(today)) {
            return null;
        }

        return {
            expiresOn,
        };
    }, [user]);
};

export default useMembership;
