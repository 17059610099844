import { addressSchema } from "@/components/AddressFieldset/AddressFieldset";
import { creditCardSchema } from "@/components/CreditCardFieldset/CreditCardFieldset";
import useDispatchAcceptJs, { AcceptJsError } from "@/hooks/useDispatchAcceptJs";
import useHandleMutation from "@/hooks/useHandleMutation";
import { useCreateDonationMutation } from "@/mutations/donation";
import type { CustomerDonation } from "@/types/donation";
import { parseAmount } from "@/utils/parse.js";
import { errorMap, phoneNumberSchema } from "@/utils/zod";
import { useAuth0 } from "@auth0/auth0-react";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Container, Divider, LinearProgress, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import { useEffect, useMemo } from "react";
import type { CardData, DispatchDataResponse } from "react-acceptjs";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import DonationAmount from "./DonationAmount.js";
import MoreWaysToHelp from "./MoreWaysToHelp.js";
import PaymentInformation from "./PaymentInformation.js";
import PersonalInformation from "./PersonalInformation.js";

const membershipThreshold = 75_00;

const schema = z.preprocess(
    (value) => {
        if (
            typeof value === "object" &&
            value &&
            "billingAddressSameAsPersonalAddress" in value &&
            value.billingAddressSameAsPersonalAddress
        ) {
            return { ...value, billingAddress: undefined };
        }

        return value;
    },
    z.object({
        amount: z
            .string()
            .min(1)
            .refine((value) => Number.parseInt(value, 10) >= 10, "Minimum amount is $10"),
        acknowledgements: z.string().trim(),
        recurringDonation: z.boolean(),
        isSplitIntoMonthlyDonations: z.enum(["true", "false"]),
        phoneNumber: phoneNumberSchema,
        emailAddress: z.string().email(),
        personalAddress: addressSchema,
        billingAddressSameAsPersonalAddress: z.boolean(),
        billingAddress: addressSchema.optional(),
        creditCard: creditCardSchema,
    }),
);

export type DonationFormValues = z.infer<typeof schema>;

const Donate = (): ReactNode => {
    const auth0 = useAuth0();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const handleMutation = useHandleMutation();
    const dispatchAcceptJs = useDispatchAcceptJs();
    const createDonationMutation = useCreateDonationMutation();

    const forceLogin = searchParams.has("force-login");

    useEffect(() => {
        if (forceLogin && !auth0.isLoading && !auth0.isAuthenticated) {
            void auth0.loginWithRedirect({
                appState: {
                    returnTo: "/donate",
                },
            });
        }
    }, [auth0.isLoading, auth0.isAuthenticated, auth0.loginWithRedirect, forceLogin]);

    const form = useForm<DonationFormValues>({
        resolver: zodResolver(schema, { errorMap }),
        defaultValues: {
            amount: "",
            acknowledgements: "",
            recurringDonation: true,
            isSplitIntoMonthlyDonations: "true",
            billingAddressSameAsPersonalAddress: true,
        },
    });

    const recurringDonation = form.watch("recurringDonation");
    const isSplitIntoMonthlyDonations = form.watch("isSplitIntoMonthlyDonations") === "true";
    const donationAmount = parseAmount(form.watch("amount"));

    const annualDonationAmount = useMemo(() => {
        if (donationAmount === null) {
            return null;
        }

        if (!(recurringDonation && isSplitIntoMonthlyDonations)) {
            return donationAmount;
        }

        return donationAmount * 12;
    }, [recurringDonation, isSplitIntoMonthlyDonations, donationAmount]);

    const handleSubmit = async (values: DonationFormValues) => {
        const { amount: rawAmount, creditCard, ...rest } = values;
        const [expirationMonth, expirationYear] = creditCard.expirationDate.split("/");
        const creditCardAddress = values.billingAddress ?? values.personalAddress;
        const amount = parseAmount(rawAmount);
        const isSplitIntoMonthlyDonations = values.isSplitIntoMonthlyDonations === "true";

        if (!amount) {
            throw new Error("Donation amount is not set");
        }

        const cardData: CardData = {
            cardNumber: creditCard.cardNumber,
            month: expirationMonth,
            year: expirationYear,
            cardCode: creditCard.cvv,
            fullName: `${creditCardAddress.firstName} ${creditCardAddress.lastName}`,
            zip: creditCardAddress.zipCode,
        };

        let opaqueAuthorizeNetData: DispatchDataResponse;

        try {
            opaqueAuthorizeNetData = await dispatchAcceptJs(cardData);
        } catch (error) {
            if (error instanceof AcceptJsError) {
                form.setError("creditCard.cardNumber", { type: "custom", message: error.message });
            }

            return;
        }

        const id = crypto.randomUUID();

        const customerDonation: CustomerDonation = {
            ...rest,
            id: id,
            opaqueAuthorizeNetData: {
                descriptor: opaqueAuthorizeNetData.opaqueData.dataDescriptor,
                value: opaqueAuthorizeNetData.opaqueData.dataValue,
            },
            amount,
            acknowledgements: values.acknowledgements ? values.acknowledgements : "",
            isSplitIntoMonthlyDonations: isSplitIntoMonthlyDonations,
        };

        const mutationResult = await handleMutation(createDonationMutation, customerDonation);

        if (mutationResult.success) {
            navigate({
                pathname: `/donate/success/${id}`,
            });
        }
    };

    if (forceLogin && !auth0.isAuthenticated) {
        return <LinearProgress />;
    }

    return (
        <Container sx={{ py: 3 }}>
            <Grid container spacing={3}>
                <Grid xs={12} md={9}>
                    <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                        <Typography variant="h5">Donate Today!</Typography>
                        <Typography sx={{ my: 2 }}>
                            We believe in quality arts education for everyone. Your donation and
                            membership helps Old Town School support this mission and provide
                            financial aid and arts programming for thousands of Chicagoans every
                            session.
                        </Typography>
                        <Typography sx={{ my: 2 }}>
                            Donations of $75 and above are eligible for membership benefits.{" "}
                            <Link
                                href="https://www.oldtownschool.org/support/membership"
                                sx={{ textDecoration: "none" }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                More Info
                            </Link>
                        </Typography>

                        {annualDonationAmount !== null &&
                            annualDonationAmount >= membershipThreshold && (
                                <Alert severity="info" sx={{ mt: 2 }}>
                                    You will be enrolled as a member.
                                </Alert>
                            )}

                        <Divider sx={{ my: 2 }} />

                        <DonationAmount form={form} />

                        <RhfTextField
                            control={form.control}
                            helperText='(example: In honor of "Jane Doe")'
                            label="Acknowledgements:"
                            name="acknowledgements"
                            fullWidth
                            sx={{ mt: 2 }}
                        />

                        <PersonalInformation form={form} />
                        <PaymentInformation form={form} />

                        <Divider sx={{ my: 2 }} />
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={createDonationMutation.isPending}
                        >
                            <span>Submit Your Donation</span>
                        </LoadingButton>
                    </form>
                </Grid>
                <MoreWaysToHelp />
            </Grid>
        </Container>
    );
};

export default Donate;
