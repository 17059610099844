import useOidcFetch from "@/hooks/useOidcFetch.js";
import type { OpaqueData } from "@/types/authorize-net.js";
import { apiUrl } from "@/utils/api.js";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type CreatePaymentProfileValues = {
    opaqueAuthorizeNetData: OpaqueData;
};

export const useCreatePaymentProfileMutation = (): UseMutationResult<
    void,
    Error,
    CreatePaymentProfileValues
> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation({
        mutationFn: async (values: CreatePaymentProfileValues) => {
            const response = await fetch(apiUrl("/payment-profiles").toString(), {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to create payment profile");
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["payment-profiles"] });
        },
    });
};

type SetDefaultPaymentProfileValues = {
    id: string;
};

export const useSetDefaultPaymentProfileMutation = (): UseMutationResult<
    void,
    Error,
    SetDefaultPaymentProfileValues
> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation({
        mutationFn: async (values: SetDefaultPaymentProfileValues) => {
            const response = await fetch(apiUrl(`/payment-profiles/${values.id}`).toString(), {
                method: "PATCH",
                body: JSON.stringify({ isDefault: true }),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to updated payment profile");
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["payment-profiles"] });
        },
    });
};

type UpdatePaymentProfileValues = {
    id: string;
    opaqueAuthorizeNetData: OpaqueData;
};

export const useUpdatePaymentProfileMutation = (): UseMutationResult<
    void,
    Error,
    UpdatePaymentProfileValues
> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation({
        mutationFn: async (values: UpdatePaymentProfileValues) => {
            const response = await fetch(apiUrl(`/payment-profiles/${values.id}`).toString(), {
                method: "PATCH",
                body: JSON.stringify({ opaqueAuthorizeNetData: values.opaqueAuthorizeNetData }),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to updated payment profile");
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["payment-profiles"] });
        },
    });
};

type DeletePaymentProfileValues = {
    id: string;
};

export const useDeletePaymentProfileCardMutation = (): UseMutationResult<
    void,
    Error,
    DeletePaymentProfileValues
> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation({
        mutationFn: async (values: DeletePaymentProfileValues) => {
            const response = await fetch(apiUrl(`/payment-profiles/${values.id}`).toString(), {
                method: "DELETE",
            });

            if (!response.ok) {
                throw new Error("Failed to delete payment profile");
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["payment-profiles"] });
        },
    });
};
