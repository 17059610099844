import type { GiftCertificateFormValues } from "@/pages/GiftCertificate/GiftCertificateForm/GiftCertificateForm";
import { createMaskedInput } from "@/utils/masked-input";
import { InputAdornment, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { RhfRadioGroup, RhfTextField } from "mui-rhf-integration";
import type { UseFormReturn } from "react-hook-form";

const MaskedGiftAmountInput = createMaskedInput({
    mask: Number,
    scale: 0,
    thousandsSeparator: ",",
    max: 100_000,
});

type Props = {
    form: UseFormReturn<GiftCertificateFormValues>;
};

const CertificateOptions = ({ form }: Props): JSX.Element => {
    return (
        <Stack spacing={2}>
            <Typography variant="h6">Certificate Options</Typography>
            <Stack direction={{ md: "row", sm: "column" }} spacing={2}>
                <Stack direction={{ md: "row", sm: "row", xs: "column" }} spacing={2}>
                    <RhfTextField
                        control={form.control}
                        label="From"
                        name="from"
                        helperText="Your name as you'd like it to appear on the certificate"
                        required
                        sx={{ flexGrow: 1 }}
                    />
                    <RhfTextField
                        control={form.control}
                        label="To"
                        name="to"
                        helperText="The recipient's name as you'd like it to appear on the certificate"
                        sx={{ flexGrow: 1 }}
                    />
                </Stack>
                <RhfTextField
                    control={form.control}
                    label="Gift Certificate Amount"
                    name="amount"
                    required
                    InputProps={{
                        inputComponent: MaskedGiftAmountInput,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    fullWidth
                    sx={{ flexGrow: 2, mt: { sm: 0, xs: 2 }, width: { md: 200, sm: "100%" } }}
                />
            </Stack>
            <RhfRadioGroup
                control={form.control}
                name="deliveryOption"
                label="Delivery Options:"
                options={[
                    {
                        value: "toRecipientByMail",
                        label: "Print and mail it to the recipient",
                    },
                    {
                        value: "toRecipientByEmail",
                        label: "Email a copy to the recipient",
                    },
                    {
                        value: "toCustomerByMail",
                        label: "Print and mail it to me",
                    },
                    {
                        value: "toCustomerByEmail",
                        label: "Email a copy to me",
                    },
                ]}
            />
        </Stack>
    );
};

export default CertificateOptions;
