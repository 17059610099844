import type { InputBaseComponentProps } from "@mui/material";
import type { ChangeEvent, ReactNode } from "react";
import { forwardRef } from "react";
import PhoneInput from "react-phone-number-input/input";

const PhoneNumberInput = forwardRef<HTMLInputElement, InputBaseComponentProps>(
    (props, ref): ReactNode => {
        const { onChange, name, ...rest } = props;

        if (typeof name !== "string") {
            throw new Error('"name" property must be defined');
        }

        return (
            <PhoneInput
                {...rest}
                name={name}
                onChange={(value) => {
                    if (!onChange) {
                        return;
                    }

                    const event: ChangeEvent<HTMLInputElement> = {
                        target: { value, name },
                    } as unknown as ChangeEvent<HTMLInputElement>;

                    onChange(event);
                }}
                ref={ref}
                country="US"
                international={false}
            />
        );
    },
);
PhoneNumberInput.displayName = "PhoneInput";

export default PhoneNumberInput;
