import type { ReservedEvent } from "@/types/event.js";
import type { Seat } from "@/types/reservation.js";
import { currencyFormatter } from "@/utils/format.js";
import AccessibleIcon from "@mui/icons-material/Accessible";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, Card, CardContent, CardHeader, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import type { ReactNode } from "react";
import { useState } from "react";

type Props = {
    event: ReservedEvent;
    seat: Seat;
    table?: string;
    showMemberPrices: boolean;
    onRemove: (seat: Seat) => Promise<void>;
};

const SeatCard = ({ event, seat, table, showMemberPrices, onRemove }: Props): ReactNode => {
    const [removing, setRemoving] = useState(false);

    const seatMeta = event.seats.get(`${seat.row}-${seat.seatNumber}`);
    const price = (seatMeta?.price ?? event.price)[showMemberPrices ? "member" : "general"];
    const fee = seatMeta?.fee ?? event.fee;

    let title = `Row: ${seat.row} / Seat: ${seat.seatNumber}`;

    if (table) {
        title = `Table: ${table} / Seat: ${seat.seatNumber}`;
    }

    return (
        <Card>
            <CardHeader
                title={title}
                sx={{ pb: 0 }}
                action={
                    <IconButton
                        disabled={removing}
                        onClick={() => {
                            setRemoving(true);
                            onRemove(seat).finally(() => {
                                setRemoving(false);
                            });
                        }}
                    >
                        <RemoveCircleIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <Box sx={{ display: "flex" }}>
                    <Typography sx={{ flexGrow: 1 }}>
                        {currencyFormatter.format(price / 100)}
                        {fee > 0 && ` (+${currencyFormatter.format(fee / 100)} fee)`}
                    </Typography>

                    {seatMeta?.accessible && <AccessibleIcon />}
                </Box>
            </CardContent>
        </Card>
    );
};

export default SeatCard;
