import type { Cart, PromoCodeDiscount } from "@/types/cart.js";

export const getAppliedDiscounts = (
    cart: Cart,
    item: { promoCodeDiscount: PromoCodeDiscount | null },
): string[] => {
    const discountsApplied = [];

    if (cart.membershipRank !== null) {
        discountsApplied.push("Member");
    }

    if (item.promoCodeDiscount) {
        discountsApplied.push("Promo");
    }

    return discountsApplied;
};
