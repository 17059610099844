import CreditCardFieldset, {
    creditCardSchema,
} from "@/components/CreditCardFieldset/CreditCardFieldset.js";
import FormDialog, { DialogForm } from "@/components/FormDialog/index.js";
import type { FormDialogFormProps } from "@/components/FormDialog/index.js";
import useDispatchAcceptJs, { AcceptJsError } from "@/hooks/useDispatchAcceptJs.js";
import type { OpaqueData } from "@/types/authorize-net.js";
import { errorMap } from "@/utils/zod.js";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useCallback } from "react";
import type { CardData, DispatchDataResponse } from "react-acceptjs";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    fullName: z.string().trim().min(1),
    zipCode: z.string().trim().min(1),
    creditCard: creditCardSchema,
});

type FormValues = z.infer<typeof schema>;

type Props = {
    open: boolean;
    onClose: () => void;
    onSubmit: (opaqueData: OpaqueData) => Promise<void>;
};

type FormProps = FormDialogFormProps<FormValues> & Omit<Props, "open">;

const CardForm = ({ onSubmit, onClose, wrapSubmit }: FormProps): ReactNode => {
    const dispatchAcceptJs = useDispatchAcceptJs();
    const { enqueueSnackbar } = useSnackbar();
    const form = useForm<FormValues>({
        resolver: zodResolver(schema, { errorMap }),
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies(wrapSubmit): Not an outer scope value
    const handleSubmit = useCallback(
        wrapSubmit(async (values: FormValues) => {
            const [expirationMonth, expirationYear] = values.creditCard.expirationDate.split("/");
            const cardData: CardData = {
                cardNumber: values.creditCard.cardNumber,
                month: expirationMonth,
                year: expirationYear,
                cardCode: values.creditCard.cvv,
                fullName: values.fullName,
                zip: values.zipCode,
            };
            let opaqueAuthorizeNetResponse: DispatchDataResponse;

            try {
                opaqueAuthorizeNetResponse = await dispatchAcceptJs(cardData);
            } catch (error) {
                if (error instanceof AcceptJsError) {
                    form.setError("creditCard.cardNumber", {
                        type: "custom",
                        message: error.message,
                    });
                }

                return;
            }

            try {
                await onSubmit({
                    value: opaqueAuthorizeNetResponse.opaqueData.dataValue,
                    descriptor: opaqueAuthorizeNetResponse.opaqueData.dataDescriptor,
                });
            } catch {
                enqueueSnackbar("Failed to submit card details", { variant: "error" });
            }
        }),
        [wrapSubmit],
    );

    return (
        <DialogForm onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <DialogTitle>Enter card details</DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <RhfTextField control={form.control} name="fullName" label="Full name" />

                    <RhfTextField control={form.control} name="zipCode" label="ZIP code" />

                    <CreditCardFieldset prefix="creditCard" form={form} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                    disabled={form.formState.isSubmitting}
                >
                    Cancel
                </Button>
                <LoadingButton loading={form.formState.isSubmitting} type="submit" color="primary">
                    Save
                </LoadingButton>
            </DialogActions>
        </DialogForm>
    );
};

const CardDialog = ({ open, onClose, ...formProps }: Props): ReactNode => {
    return (
        <FormDialog
            FormComponent={CardForm}
            formProps={formProps}
            dialogProps={{
                maxWidth: "sm",
                fullWidth: true,
            }}
            open={open}
            onClose={onClose}
        />
    );
};

export default CardDialog;
