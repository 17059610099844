import memoize from "nano-memoize";
import type { Block, FloorName, Seat, SeatingLayout, Section } from "./types.js";

const getSeatsFromSection = (section: Section, floorName: FloorName): Seat[] => {
    const seats: Seat[] = [];

    for (const row of section.rows) {
        switch (row.type) {
            case "seats": {
                const startNumber = Math.min(row.startNumber, row.endNumber);
                const endNumber = Math.max(row.startNumber, row.endNumber);

                for (let i = startNumber; i < endNumber; ++i) {
                    seats.push({ floor: floorName, row: row.name, seatNumber: i });
                }

                break;
            }

            case "tables":
                for (const table of row.tables) {
                    seats.push(
                        ...[
                            { floor: floorName, row: table, isTable: true, seatNumber: 1 },
                            { floor: floorName, row: table, isTable: true, seatNumber: 2 },
                            { floor: floorName, row: table, isTable: true, seatNumber: 3 },
                            { floor: floorName, row: table, isTable: true, seatNumber: 4 },
                        ],
                    );
                }

                break;
        }
    }

    return seats;
};

const getSeatsFromFloor = (floor: Block[], floorName: FloorName): Seat[] => {
    const seats: Seat[] = [];

    for (const block of floor) {
        seats.push(
            ...getSeatsFromSection(block.sections.left, floorName),
            ...getSeatsFromSection(block.sections.center, floorName),
            ...getSeatsFromSection(block.sections.right, floorName),
        );
    }

    return seats;
};

export const enumerateSeats = memoize((layout: SeatingLayout): Seat[] => {
    return [
        ...getSeatsFromFloor(layout.main, "main"),
        ...getSeatsFromFloor(layout.balcony, "balcony"),
    ];
});
