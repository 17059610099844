import useOidcFetch from "@/hooks/useOidcFetch.js";
import { mapRawToCart } from "@/mappers/cart.js";
import type { Cart, RawCart } from "@/types/cart.js";
import type { JsonApiDocument } from "@/types/json-api.js";
import { apiUrl } from "@/utils/api.js";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

export class CartExpiredError extends Error {}
export class CartFetchError extends Error {}

export const useCartQuery = (cartId: string, enabled: boolean): UseQueryResult<Cart> => {
    const fetch = useOidcFetch();

    return useQuery({
        queryKey: ["carts", cartId],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl(`/carts/${cartId}`).toString(), {
                signal,
            });

            if (response.status === 410) {
                throw new CartExpiredError("Cart has expired");
            }

            if (!response.ok) {
                throw new CartFetchError(`Unable to fetch cart with ID "${cartId}"`);
            }

            const raw = (await response.json()) as JsonApiDocument<RawCart>;
            return mapRawToCart(raw.data);
        },
        enabled,
        retry: false,
    });
};
