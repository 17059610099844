import useAddSeatsToReservation from "@/hooks/useAddSeatsToReservation.js";
import useRemoveSeatFromReservation from "@/hooks/useRemoveSeatFromReservation";
import { enumerateSeats } from "@/seating-layouts/enumerate.js";
import type { Seat } from "@/seating-layouts/index.js";
import { floorLabel, getLayout } from "@/seating-layouts/index.js";
import type { Price, ReservedEvent, SeatMeta } from "@/types/event.js";
import type { Reservation } from "@/types/reservation.js";
import { currencyFormatter } from "@/utils/format.js";
import { seatKey } from "@/utils/reservation";
import AccessibleIcon from "@mui/icons-material/Accessible";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Slider,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import RenderIfVisible from "react-render-if-visible";

type TabValue = "lowest-price" | "best-available";

type SeatWithMeta = Seat & {
    price: number;
    meta?: SeatMeta;
};

const collator = new Intl.Collator("en-US");

const priceCompare = (a: SeatWithMeta, b: SeatWithMeta): number => {
    const priceDiff = a.price - b.price;

    if (priceDiff !== 0) {
        return priceDiff;
    }

    const tableDiff = (a.isTable ? 1 : 0) - (b.isTable ? 1 : 0);

    if (tableDiff !== 0) {
        return tableDiff;
    }

    const rowDiff = collator.compare(a.row, b.row);

    if (rowDiff !== 0) {
        return rowDiff;
    }

    return a.seatNumber - b.seatNumber;
};

type Props = {
    open: boolean;
    onClose: () => void;
    event: ReservedEvent;
    priceCategory: keyof Price;
    reservation?: Reservation;
};

const SeatFinderDialog = ({
    open,
    onClose,
    event,
    priceCategory,
    reservation,
}: Props): ReactNode => {
    const [activeTab, setActiveTab] = useState<TabValue>("lowest-price");
    const theme = useTheme();
    const useFullscreen = useMediaQuery(theme.breakpoints.down("sm"));
    const addSeatsToReservation = useAddSeatsToReservation();
    const removeSeatFromReservation = useRemoveSeatFromReservation();

    const seats = useMemo(() => {
        const layout = getLayout(event.layout);
        const seats = enumerateSeats(layout);

        return seats
            .map((seat): SeatWithMeta => {
                const meta = event.seats.get(seatKey(seat));

                return {
                    ...seat,
                    price: meta?.price?.[priceCategory] ?? event.price[priceCategory],
                    meta,
                };
            })
            .filter((seat) => !seat.meta?.reserved);
    }, [event, priceCategory]);

    const [minPrice, maxPrice, priceMarks] = useMemo(() => {
        const prices = [...new Set(seats.map((seat) => seat.price))];
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);

        return [
            minPrice,
            maxPrice,
            [
                { value: minPrice, label: currencyFormatter.format(minPrice / 100) },
                { value: maxPrice, label: currencyFormatter.format(maxPrice / 100) },
            ],
        ];
    }, [seats]);

    const [priceFilter, setPriceFilter] = useState<[number, number]>([minPrice, maxPrice]);
    const [committedPriceFilter, setCommittedPriceFilter] = useState<[number, number]>([
        minPrice,
        maxPrice,
    ]);

    useEffect(() => {
        setPriceFilter([minPrice, maxPrice]);
        setCommittedPriceFilter([minPrice, maxPrice]);
    }, [minPrice, maxPrice]);

    const filteredSeats = useMemo(() => {
        return seats.filter(
            (seat) =>
                seat.price >= committedPriceFilter[0] && seat.price <= committedPriceFilter[1],
        );
    }, [seats, committedPriceFilter]);

    const sortedSeats = useMemo(() => {
        let sort: (a: SeatWithMeta, b: SeatWithMeta) => number;

        switch (activeTab) {
            case "lowest-price":
                sort = priceCompare;
                break;

            case "best-available":
                sort = (a: SeatWithMeta, b: SeatWithMeta) => {
                    const diff = (b.meta?.priority ?? 0) - (a.meta?.priority ?? 0);

                    if (diff !== 0) {
                        return diff;
                    }

                    return priceCompare(a, b);
                };

                break;
        }

        return [...filteredSeats].sort(sort);
    }, [filteredSeats, activeTab]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth fullScreen={useFullscreen}>
            <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                Find Seats
                <Button onClick={onClose} sx={{ ml: "auto" }} variant="outlined">
                    Continue with selected
                </Button>
            </DialogTitle>
            <Divider />
            <Box sx={{ px: 6, py: 1 }}>
                <Slider
                    min={minPrice}
                    max={maxPrice}
                    valueLabelFormat={(value) => currencyFormatter.format(value / 100)}
                    marks={priceMarks}
                    valueLabelDisplay="auto"
                    value={priceFilter}
                    onChange={(_event, value) => {
                        setPriceFilter(value as [number, number]);
                    }}
                    onChangeCommitted={(_event, value) => {
                        setCommittedPriceFilter(value as [number, number]);
                    }}
                    disabled={minPrice === maxPrice}
                />
            </Box>
            <Divider />
            <Tabs
                variant="fullWidth"
                value={activeTab}
                onChange={(_event, value: TabValue) => {
                    setActiveTab(value);
                }}
            >
                <Tab label="Lowest Price" value="lowest-price" />
                <Tab label="Best Available" value="best-available" />
            </Tabs>
            <DialogContent dividers sx={{ p: 0 }}>
                <List disablePadding>
                    {sortedSeats.length === 0 && (
                        <ListItem>
                            <ListItemText primary="No seats available" />
                        </ListItem>
                    )}

                    {sortedSeats.map((seat) => {
                        const key = seatKey(seat);
                        const selected = reservation?.seats.get(key);

                        return (
                            <RenderIfVisible key={key}>
                                <ListItem
                                    disablePadding
                                    sx={{
                                        backgroundColor: selected ? "secondary.light" : undefined,
                                    }}
                                >
                                    <ListItemButton
                                        onClick={() => {
                                            if (selected) {
                                                if (!reservation) {
                                                    return;
                                                }

                                                void removeSeatFromReservation(
                                                    reservation,
                                                    selected,
                                                );
                                                return;
                                            }

                                            void addSeatsToReservation(
                                                event.id,
                                                seat.isTable ? `table${seat.row}` : seat.row,
                                                [seat.seatNumber],
                                                reservation,
                                            );
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                seat.isTable
                                                    ? `Table ${seat.row}`
                                                    : `Row ${seat.row}`
                                            }
                                            secondary={floorLabel(seat.floor)}
                                            sx={{ flexBasis: 1 }}
                                        />
                                        <ListItemText
                                            primary={
                                                <Box display="flex">
                                                    {seat.meta?.accessible && <AccessibleIcon />}
                                                    Seat {seat.seatNumber}
                                                </Box>
                                            }
                                            sx={{ flexBasis: 1 }}
                                        />
                                        <ListItemText
                                            primary={currencyFormatter.format(seat.price / 100)}
                                            sx={{ flexBasis: 1 }}
                                        />
                                        {selected ? <RemoveIcon /> : <AddIcon />}
                                    </ListItemButton>
                                </ListItem>
                            </RenderIfVisible>
                        );
                    })}
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default SeatFinderDialog;
