import PaymentProfilesListItem from "@/pages/PaymentProfiles/PaymentProfilesListItem.js";
import type { PaymentProfile } from "@/types/payment-profile.js";
import { Alert, List } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    paymentProfiles: PaymentProfile[];
};

const PaymentProfilesList = ({ paymentProfiles }: Props): ReactNode => {
    if (paymentProfiles.length === 0) {
        return <Alert severity="info">You haven't set up any payment profiles yet.</Alert>;
    }

    return (
        <List disablePadding>
            {paymentProfiles.map((paymentProfile) => (
                <PaymentProfilesListItem key={paymentProfile.id} paymentProfile={paymentProfile} />
            ))}
        </List>
    );
};

export default PaymentProfilesList;
