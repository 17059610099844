import type { InputBaseComponentProps } from "@mui/material";
import type { MaskedPattern } from "imask";
import type { ChangeEvent, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from "react";
import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

type MaskedInputOptions = {
    mask: string | NumberConstructor | RegExp;
    scale?: number;
    thousandsSeparator?: string;
    max?: number;
    lazy?: boolean;
    blocks?: MaskedPattern["blocks"];
    eager?: boolean;
};

export const createMaskedInput = (
    options: MaskedInputOptions,
): ForwardRefExoticComponent<
    PropsWithoutRef<InputBaseComponentProps> & RefAttributes<HTMLInputElement>
> => {
    const Component = forwardRef<HTMLInputElement, InputBaseComponentProps>(
        (props, ref): JSX.Element => {
            const { onChange, name, ...propsRest } = props;
            const { mask, ...optionsRest } = options;

            if (typeof name !== "string") {
                throw new Error('"name" property must be defined');
            }

            return (
                <IMaskInput
                    {...propsRest}
                    {...optionsRest}
                    mask={mask as string}
                    name={name}
                    inputRef={ref}
                    onChange={() => {
                        // No-op (to satisfy React checks)
                    }}
                    onAccept={(value) => {
                        if (!onChange) {
                            return;
                        }

                        const event: ChangeEvent<HTMLInputElement> = {
                            target: { value, name },
                        } as unknown as ChangeEvent<HTMLInputElement>;

                        onChange(event);
                    }}
                />
            );
        },
    );
    Component.displayName = "MaskedInput";

    return Component;
};
