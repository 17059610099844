import { mapRawToReservation } from "@/mappers/reservation.js";
import type { JsonApiDocument } from "@/types/json-api.js";
import type { RawReservation, Reservation } from "@/types/reservation.js";
import { apiUrl } from "@/utils/api.js";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

export const useReservationQuery = (reservationId: string | null): UseQueryResult<Reservation> => {
    return useQuery({
        queryKey: ["reservations", reservationId],
        queryFn: async ({ signal }) => {
            if (!reservationId) {
                throw new Error("Reservation ID missing");
            }

            const response = await fetch(apiUrl(`/reservations/${reservationId}`).toString(), {
                signal,
            });

            if (!response.ok) {
                throw new Error(`Unable to fetch reservation with ID "${reservationId}"`);
            }

            const raw = (await response.json()) as JsonApiDocument<RawReservation>;
            return mapRawToReservation(raw.data);
        },
        enabled: reservationId !== null,
        retry: false,
    });
};
