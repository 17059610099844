import { type PhoneNumber, parsePhoneNumberWithError } from "libphonenumber-js";
import { z } from "zod";

export const errorMap: z.ZodErrorMap = (issue, context) => {
    if (issue.code === z.ZodIssueCode.invalid_enum_value) {
        return { message: "Required" };
    }

    if (issue.code === z.ZodIssueCode.too_small && issue.minimum === 1 && issue.type === "string") {
        return { message: "Required" };
    }

    if (issue.code === z.ZodIssueCode.invalid_type && issue.received === "null") {
        return { message: "Required" };
    }

    return { message: context.defaultError };
};

export const phoneNumberSchema = z.string().transform((value, context) => {
    let phoneNumber: PhoneNumber;

    try {
        phoneNumber = parsePhoneNumberWithError(value, "US");
    } catch {
        context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid phone number",
        });
        return z.NEVER;
    }

    if (!phoneNumber.isValid()) {
        context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid phone number",
        });
        return z.NEVER;
    }

    return phoneNumber.format("E.164");
});
