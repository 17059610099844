import { usePostDonationInstructionQuery } from "@/queries/post-donation-instruction";
import { Button, LinearProgress, Link, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { Suspense, lazy } from "react";
import type MarkdownType from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";

const Markdown = lazy(async () => {
    return (await import("react-markdown")) as { default: typeof MarkdownType };
});

type Params = {
    donationId: string;
};

const Success = (): ReactNode => {
    const navigate = useNavigate();
    const { donationId } = useParams<Params>();

    if (!donationId) {
        throw new Error("Donation ID missing");
    }

    const instructionQuery = usePostDonationInstructionQuery(donationId);

    return (
        <>
            <Typography>Thank you for your donation</Typography>
            <Button
                onClick={() => {
                    navigate({
                        pathname: "/donate",
                    });
                }}
            >
                Go Back
            </Button>

            {!instructionQuery.data && <LinearProgress />}

            {instructionQuery.data && (
                <Suspense fallback={<LinearProgress />}>
                    <Markdown
                        skipHtml
                        allowedElements={["br", "p", "strong", "a", "ul", "li"]}
                        components={{
                            p: (props) => <Typography paragraph {...props} ref={undefined} />,
                            a: (props) => (
                                <Link
                                    {...props}
                                    color="primary"
                                    target="_blank"
                                    rel="noreferrer"
                                    ref={undefined}
                                />
                            ),
                        }}
                        unwrapDisallowed
                    >
                        {instructionQuery.data}
                    </Markdown>
                </Suspense>
            )}
        </>
    );
};

export default Success;
