import { useRemoveFromCartMutation } from "@/mutations/cart";
import { getAppliedDiscounts } from "@/pages/Checkout/Details/utils.js";
import type { Cart, GeneralAdmissionEventLineItem } from "@/types/cart.js";
import type { Price } from "@/types/event";
import { currencyFormatter } from "@/utils/format";
import { DateTimeFormatter } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { useState } from "react";
import { GeneralAdmissionEventDialog } from "../GeneralAdmissionEvent";

const dateTimeFormatter = DateTimeFormatter.ofPattern("MMMM d, yyyy – h:mm a").withLocale(
    Locale.US,
);

type Props = {
    cart: Cart;
    item: GeneralAdmissionEventLineItem;
};

const GeneralAdmissionEventItem = ({ cart, item }: Props): ReactNode => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const removeFromCartMutation = useRemoveFromCartMutation(cart.id);
    const priceCategory: keyof Price = cart.membershipRank !== null ? "member" : "general";

    const totalPrice = (item.event.price[priceCategory] / 100) * item.numberOfTickets;
    const totalFee = (item.event.fee / 100) * item.numberOfTickets;
    const discountsApplied = getAppliedDiscounts(cart, item);

    return (
        <Stack sx={{ p: 2 }} spacing={2} direction="row">
            <Box sx={{ flexGrow: 1 }}>
                <Typography fontWeight="bold">{item.event.title}</Typography>
                <Typography>
                    {item.event.date.atTime(item.event.startTime).format(dateTimeFormatter)}
                </Typography>
                <Typography>{item.event.location.name}</Typography>
                <Typography>
                    {item.numberOfTickets} Ticket{item.numberOfTickets !== 1 && "s"}
                </Typography>
                <Typography>
                    Price: {currencyFormatter.format(totalPrice)}
                    {totalFee > 0 && ` (+${currencyFormatter.format(totalFee)} fee)`}
                    {discountsApplied.length > 0 && ` (${discountsApplied.join(" & ")} applied)`}
                </Typography>
            </Box>
            <Box>
                <IconButton
                    onClick={() => {
                        setDialogOpen(true);
                    }}
                >
                    <EditIcon />
                </IconButton>
                <IconButton
                    edge="end"
                    color="error"
                    onClick={() => {
                        removeFromCartMutation.mutate({
                            itemId: item.id,
                        });
                    }}
                >
                    <RemoveCircleIcon />
                </IconButton>
            </Box>

            <GeneralAdmissionEventDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                eventId={item.event.id}
                cart={cart}
                lineItem={item}
            />
        </Stack>
    );
};

export default GeneralAdmissionEventItem;
