import { useAddToCartMutation } from "@/mutations/cart";
import type { Cart } from "@/types/cart";
import { Backdrop, CircularProgress } from "@mui/material";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const checkoutReservationIdKey = "checkoutReservationId";

type Props = {
    cart: Cart;
};

const AddReservation = ({ cart }: Props): ReactNode => {
    const addToCartMutation = useAddToCartMutation(cart.id);
    const [searchParams, setSearchParams] = useSearchParams();
    const reservationId = searchParams.get("reservationId");

    useEffect(() => {
        if (!reservationId) {
            return;
        }

        setSearchParams({}, { replace: true });
        const storedReservationId = window.sessionStorage.getItem(checkoutReservationIdKey);

        if (!storedReservationId || reservationId !== storedReservationId) {
            return;
        }

        window.sessionStorage.removeItem(checkoutReservationIdKey);

        if (
            cart.lineItems.some(
                (item) =>
                    item.type === "reserved-event" && item.reservation.id === storedReservationId,
            )
        ) {
            return;
        }

        window.requestAnimationFrame(() => {
            addToCartMutation.mutate({
                type: "reserved-event",
                reservationId: storedReservationId,
            });
        });
    }, [reservationId, addToCartMutation, cart, setSearchParams]);

    return (
        <Backdrop open={addToCartMutation.isPending}>
            <CircularProgress />
        </Backdrop>
    );
};

export default AddReservation;
