export const findLastIndex = <T>(
    array: T[],
    predicate: (element: T, index: number, array: T[]) => boolean,
) => {
    const length = array.length;

    if (length === 0) {
        return -1;
    }

    for (let index = length - 1; index >= 0; index--) {
        if (predicate(array[index], index, array)) {
            return index;
        }
    }

    return -1;
};
