import type { Cart } from "@/types/cart.js";
import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";
import GeneralAdmissionEventDialog from "./GeneralAdmissionEventDialog";

type Props = {
    cart: Cart;
};

const AddGeneralAdmissionEvent = ({ cart }: Props): ReactNode => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [eventId, setEventId] = useState<string | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const addEventId = searchParams.get("generalAdmissionEventId");

    useEffect(() => {
        if (addEventId) {
            setEventId(addEventId);
            setDialogOpen(true);
        }
    }, [addEventId]);

    if (!eventId) {
        return null;
    }

    return (
        <GeneralAdmissionEventDialog
            open={dialogOpen}
            onClose={() => {
                const referrer = searchParams.get("referrer");

                if (referrer && cart.lineItems.length === 0) {
                    window.location.href = referrer;
                    return;
                }

                setDialogOpen(false);
                setSearchParams({}, { replace: true });
            }}
            eventId={eventId}
            cart={cart}
        />
    );
};

export default AddGeneralAdmissionEvent;
