import { useCreateReservationMutation } from "@/mutations/reservation.js";
import type { ReservedEvent } from "@/types/event.js";
import { errorMap } from "@/utils/zod.js";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import { InputAdornment, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    presaleCode: z.string().trim().min(1),
});

type FormValues = z.infer<typeof schema>;

type Props = {
    event: ReservedEvent;
};

const PresaleCodeForm = ({ event }: Props): ReactNode => {
    const createReservationMutation = useCreateReservationMutation();

    const form = useForm({
        resolver: zodResolver(schema, { errorMap }),
        defaultValues: { presaleCode: "" },
    });

    const handleSubmit = (values: FormValues) => {
        createReservationMutation.mutate({
            eventId: event.id,
            presaleCode: values.presaleCode,
        });
    };

    return (
        <Paper sx={{ mt: 4, p: 2 }} variant="outlined">
            <Typography variant="h6">Presale</Typography>

            <Typography>
                If you have a presale code, you can enter it below in order to buy tickets
                immediately.
            </Typography>

            <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                <RhfTextField
                    control={form.control}
                    name="presaleCode"
                    label="Presale Code"
                    required
                    sx={{ mt: 2 }}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LoadingButton
                                    type="submit"
                                    loading={createReservationMutation.isPending}
                                >
                                    Submit
                                </LoadingButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </form>
        </Paper>
    );
};

export default PresaleCodeForm;
