import { useApplyPromoCodeMutation } from "@/mutations/cart.js";
import type { Cart } from "@/types/cart.js";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, InputAdornment } from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const promoCodeSchema = z.object({
    code: z.string().trim().min(1),
});

type GiftCertificateFormValues = z.infer<typeof promoCodeSchema>;

type Props = {
    cart: Cart;
};

const ApplyPromoCode = ({ cart }: Props): ReactNode => {
    const { enqueueSnackbar } = useSnackbar();
    const applyPromoCodeMutation = useApplyPromoCodeMutation(cart.id);
    const form = useForm<GiftCertificateFormValues>({
        resolver: zodResolver(promoCodeSchema),
    });

    const handleSubmit = (values: GiftCertificateFormValues) => {
        applyPromoCodeMutation.mutate(values, {
            onSuccess: () => {
                form.reset();
                enqueueSnackbar("Promo code has been applied", { variant: "success" });
            },
            onError: (error) => {
                enqueueSnackbar(error.message, { variant: "error" });
            },
        });
    };

    return (
        <Box
            component="form"
            sx={{ flexGrow: 1 }}
            onSubmit={form.handleSubmit(handleSubmit)}
            noValidate
        >
            <RhfTextField
                control={form.control}
                label="Promo Code"
                name="code"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <LoadingButton
                                disabled={!form.formState.isValid}
                                loading={applyPromoCodeMutation.isPending}
                                type="submit"
                            >
                                Apply
                            </LoadingButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
            />
        </Box>
    );
};

export default ApplyPromoCode;
