import { mapRawToPointInTime } from "@/mappers/generic";
import type { Event, GenericEvent, RawEvent } from "@/types/event.js";
import { LocalDate, LocalTime } from "@js-joda/core";

export const mapRawToEvent = (raw: RawEvent): Event => {
    const genericEvent: GenericEvent = {
        ...raw,
        date: LocalDate.parse(raw.date),
        startTime: LocalTime.parse(raw.startTime),
        endTime: LocalTime.parse(raw.endTime),
        saleStart: {
            general: mapRawToPointInTime(raw.saleStart.general),
            member: mapRawToPointInTime(raw.saleStart.member),
        },
        saleEnd: mapRawToPointInTime(raw.saleEnd),
    };

    switch (raw.type) {
        case "general-admission":
            return {
                ...genericEvent,
                type: raw.type,
                ticketsLeft: raw.ticketsLeft,
            };

        case "reserved":
            return {
                ...genericEvent,
                type: raw.type,
                layout: raw.layout,
                seats: new Map(raw.seats.map((seat) => [`${seat.row}-${seat.seatNumber}`, seat])),
            };
    }
};
