import { useSeatingChartContext } from "@/components/SeatingChart/SeatingChart.js";
import { Box, useTheme } from "@mui/material";
import type { ReactNode } from "react";
import { useState } from "react";
import { seatHeight, seatPadding, seatWidth } from "./utils.js";

type Props = {
    x: number;
    y: number;
    row: string;
    seatNumber: number;
    sideSelected?: boolean;
    onClick?: (seatNumber: number) => Promise<void>;
    onMouseEnter?: (seatNumber: number) => void;
    onMouseLeave?: (seatNumber: number) => void;
};

const SeatSelector = ({
    x,
    y,
    row,
    seatNumber,
    sideSelected,
    onClick,
    onMouseEnter,
    onMouseLeave,
}: Props): ReactNode => {
    const theme = useTheme();
    const { interactive, event, reservation, desiredTickets } = useSeatingChartContext();
    const [hovering, setHovering] = useState(false);

    const seat = event.seats.get(`${row}-${seatNumber}`);
    const selected = Boolean(reservation?.seats.get(`${row}-${seatNumber}`));
    const reserved = selected || Boolean(seat?.reserved);
    let fill = theme.palette.primary.main;

    if (selected) {
        fill = theme.palette.secondary.main;
    } else if (reserved) {
        fill = theme.palette.grey["500"];
    } else if (!interactive) {
        fill = theme.palette.primary.light;
    } else if (sideSelected) {
        fill = theme.palette.secondary.light;
    } else if (hovering) {
        fill = theme.palette.secondary.main;
    }

    return (
        <g transform={`translate(${x}, ${y})`} data-testid={`${row}-${seatNumber}`}>
            <Box
                x={seatPadding}
                y={seatPadding}
                component="rect"
                width={seatWidth - seatPadding * 2}
                height={seatHeight - seatPadding * 2}
                fill={fill}
                rx={5}
                ry={5}
            />
            <Box
                component="rect"
                width={seatWidth}
                height={seatHeight}
                fill="transparent"
                onClick={
                    onClick && !reserved && desiredTickets > 0
                        ? () => {
                              void onClick(seatNumber).then(() => {
                                  setHovering(false);
                              });
                          }
                        : undefined
                }
                onMouseEnter={
                    onMouseEnter && !reserved && desiredTickets > 0
                        ? () => {
                              setHovering(true);
                              onMouseEnter(seatNumber);
                          }
                        : undefined
                }
                onMouseLeave={
                    onMouseLeave && !reserved && desiredTickets > 0
                        ? () => {
                              setHovering(false);
                              onMouseLeave(seatNumber);
                          }
                        : undefined
                }
                sx={{
                    cursor: interactive && !reserved && desiredTickets > 0 ? "pointer" : undefined,
                }}
            />
            {seat?.accessible && (
                <use href="#accessible-icon" fill="#ffffff" x={1} y={1} pointerEvents="none" />
            )}
        </g>
    );
};

export default SeatSelector;
