import { useRemoveFromCartMutation } from "@/mutations/cart";
import CourseClassDialog from "@/pages/Checkout/CourseClass/CourseClassDialog";
import { getAppliedDiscounts } from "@/pages/Checkout/Details/utils.js";
import { getClassPriceCategory } from "@/pages/Checkout/utils.js";
import type { Cart, CourseClassLineItem } from "@/types/cart.js";
import type { Instrument } from "@/types/course-class";
import { findLastIndex } from "@/utils/array.ts";
import { currencyFormatter } from "@/utils/format.js";
import type { DayOfWeek, LocalDate } from "@js-joda/core";
import { ChronoUnit, DateTimeFormatter, TemporalAdjusters } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, IconButton, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import type { ReactNode } from "react";
import { useState } from "react";

type Props = {
    item: CourseClassLineItem;
    cart: Cart;
};

const dayOfWeekFormatter = DateTimeFormatter.ofPattern("eeee").withLocale(Locale.US);
const dateFormatter = DateTimeFormatter.ofPattern("M/d/yyyy").withLocale(Locale.US);
const timeFormatter = DateTimeFormatter.ofPattern("h:mm a").withLocale(Locale.US);

const findFirstDayOfWeek = (startDate: LocalDate, daysOfWeek: DayOfWeek[]): DayOfWeek => {
    const startDayOfWeekOrdinal = startDate.dayOfWeek().ordinal();
    const firstPreviousIndex = findLastIndex(
        daysOfWeek,
        (dayOfWeek) => dayOfWeek.ordinal() < startDayOfWeekOrdinal,
    );

    if (firstPreviousIndex === -1 || firstPreviousIndex + 1 === daysOfWeek.length) {
        return daysOfWeek[0];
    }

    return daysOfWeek[firstPreviousIndex + 1];
};

const findLastDayOfWeek = (endDate: LocalDate, daysOfWeek: DayOfWeek[]): DayOfWeek => {
    const endDayOfWeekOrdinal = endDate.dayOfWeek().ordinal();
    const firstPreviousIndex = findLastIndex(
        daysOfWeek,
        (dayOfWeek) => dayOfWeek.ordinal() < endDayOfWeekOrdinal,
    );

    if (firstPreviousIndex === -1 || firstPreviousIndex + 1 === daysOfWeek.length) {
        return daysOfWeek[0];
    }

    return daysOfWeek[firstPreviousIndex + 1];
};

const calculateNumberOfMeetings = (
    firstDate: LocalDate,
    lastDate: LocalDate,
    daysOfWeek: DayOfWeek[],
): number => {
    const weeks = firstDate.until(lastDate, ChronoUnit.WEEKS) + 1;
    const firstDayOfWeekOrdinal = firstDate.dayOfWeek().ordinal();
    const lastDayOfWeekOrdinal = lastDate.dayOfWeek().ordinal();

    const subtractStart = findLastIndex(
        daysOfWeek,
        (dayOfWeek) => dayOfWeek.ordinal() < firstDayOfWeekOrdinal,
    );
    const subtractEnd = daysOfWeek.findIndex(
        (dayOfWeek) => dayOfWeek.ordinal() > lastDayOfWeekOrdinal,
    );
    const numMeetings = daysOfWeek.length * weeks;

    return (
        numMeetings -
        (subtractStart + 1) -
        (subtractEnd >= 0 ? daysOfWeek.length - subtractEnd - 1 : 0)
    );
};

const CourseClassItem = ({ item, cart }: Props): ReactNode => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const removeFromCartMutation = useRemoveFromCartMutation(cart.id);
    const priceCategory = getClassPriceCategory(cart, item.student);

    const { courseClass } = item;
    const firstDate = courseClass.startDate.with(
        TemporalAdjusters.nextOrSame(
            findFirstDayOfWeek(courseClass.startDate, courseClass.daysOfWeek),
        ),
    );
    const lastDate = courseClass.endDate.with(
        TemporalAdjusters.previousOrSame(
            findLastDayOfWeek(courseClass.endDate, courseClass.daysOfWeek),
        ),
    );
    const meetings = calculateNumberOfMeetings(firstDate, lastDate, courseClass.daysOfWeek);

    const price = courseClass.price[priceCategory];
    const proratedPrice = courseClass.proratedPrice[priceCategory];
    const discountsApplied = getAppliedDiscounts(cart, item);

    let instrument: Instrument | undefined;

    if (item.instrumentId) {
        instrument = item.courseClass.instruments?.find(
            (instrument) => instrument.id === item.instrumentId,
        );
    }

    return (
        <Stack sx={{ p: 2 }} spacing={2} direction="row">
            <Box sx={{ flexGrow: 1 }}>
                <Typography fontWeight="bold">
                    {courseClass.title} {courseClass.instructor && `(${courseClass.instructor})`}
                </Typography>

                <Typography sx={{ mb: 2 }}>
                    Class Price:{" "}
                    {proratedPrice ? (
                        <>
                            <del>{currencyFormatter.format(price / 100)}</del>{" "}
                            {currencyFormatter.format(proratedPrice / 100)}
                        </>
                    ) : (
                        currencyFormatter.format(price / 100)
                    )}
                    {discountsApplied.length > 0 && ` (${discountsApplied.join(" & ")} applied)`}
                    <br />
                    {courseClass.daysOfWeek
                        .map((value) => dayOfWeekFormatter.format(value))
                        .join(", ")}
                    :<br /> {timeFormatter.format(courseClass.startTime)} -{" "}
                    {timeFormatter.format(courseClass.endTime)}
                    <br />
                    {meetings} meetings: {dateFormatter.format(firstDate)} -{" "}
                    {dateFormatter.format(lastDate)}
                    <br />
                    {courseClass.location ? `In person: ${courseClass.location}` : "Online"}
                    {item.student && (
                        <>
                            <br />
                            Student: {item.student.firstName} {item.student.lastName}
                        </>
                    )}
                    {instrument && (
                        <>
                            <br />
                            Instrument: {instrument.name}
                        </>
                    )}
                    {courseClass.paymentInstallments && item.paidInInstallments && (
                        <>
                            <br />
                            Pay in {courseClass.paymentInstallments} monthly installments of{" "}
                            {currencyFormatter.format(
                                Math.ceil(
                                    Math.floor(
                                        (proratedPrice ?? price) / courseClass.paymentInstallments,
                                    ) / 100,
                                ),
                            )}
                        </>
                    )}
                </Typography>
            </Box>
            <Box>
                <IconButton
                    onClick={() => {
                        setDialogOpen(true);
                    }}
                >
                    <EditIcon />
                </IconButton>
                <IconButton
                    edge="end"
                    color="error"
                    onClick={() => {
                        removeFromCartMutation.mutate({
                            itemId: item.id,
                        });
                    }}
                >
                    <RemoveCircleIcon />
                </IconButton>
            </Box>

            <CourseClassDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                courseClassId={item.courseClass.id}
                cart={cart}
                lineItem={item}
            />
        </Stack>
    );
};

export default CourseClassItem;
