import { useUpdateReservationMutation } from "@/mutations/reservation";
import type { Reservation, Seat } from "@/types/reservation";

export type RemoveSeatFromReservation = (reservation: Reservation, seat: Seat) => Promise<void>;

export default (): RemoveSeatFromReservation => {
    const updateReservationMutation = useUpdateReservationMutation();

    return async (reservation: Reservation, seat: Seat) => {
        await updateReservationMutation.mutateAsync({
            reservationId: reservation.id,
            seats: [...reservation.seats.values()].filter(
                (reservedSeat) =>
                    reservedSeat.row !== seat.row || reservedSeat.seatNumber !== seat.seatNumber,
            ),
        });
    };
};
