import useRemoveSeatFromReservation from "@/hooks/useRemoveSeatFromReservation";
import type { ReservedEvent } from "@/types/event.js";
import type { Reservation } from "@/types/reservation.js";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import type { ReactNode } from "react";
import { useMemo } from "react";
import HoldTimer from "./HoldTimer.js";
import SeatCard from "./SeatCard.js";

type Props = {
    event: ReservedEvent;
    reservation: Reservation;
    showMemberPrices: boolean;
};

const tableRegex = /^table([A-Z]+)$/;
const collator = new Intl.Collator("en-US");

const ReservationPanel = ({ event, reservation, showMemberPrices }: Props): ReactNode => {
    const removeSeatFromReservation = useRemoveSeatFromReservation();

    const seats = useMemo(() => {
        return [...reservation.seats.values()]
            .map((seat) => {
                const tableMatch = tableRegex.exec(seat.row);

                return {
                    ...seat,
                    table: tableMatch?.[1],
                };
            })
            .sort((a, b) => {
                const rowDiff = collator.compare(a.row, b.row);

                if (rowDiff !== 0) {
                    return rowDiff;
                }

                return a.seatNumber - b.seatNumber;
            });
    }, [reservation]);

    return (
        <Box sx={{ mt: 2 }}>
            <HoldTimer reservation={reservation} />
            <Grid container spacing={2} sx={{ mt: 1 }}>
                {seats.map((seat) => (
                    <Grid key={`${seat.row}-${seat.seatNumber}`} xs={12} sm={6} md={4} lg={3}>
                        <SeatCard
                            event={event}
                            seat={seat}
                            table={seat.table}
                            showMemberPrices={showMemberPrices}
                            onRemove={async (seat) => {
                                await removeSeatFromReservation(reservation, seat);
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ReservationPanel;
