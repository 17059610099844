import AddressFieldset from "@/components/AddressFieldset";
import PhoneNumberInput from "@/components/PhoneNumberInput";
import type { GiftCertificateFormValues } from "@/pages/GiftCertificate/GiftCertificateForm/GiftCertificateForm";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { RhfTextField } from "mui-rhf-integration";
import type { UseFormReturn } from "react-hook-form";

type Props = {
    form: UseFormReturn<GiftCertificateFormValues>;
};

const BillingInformation = ({ form }: Props): JSX.Element => {
    return (
        <Stack spacing={2}>
            <Typography variant="h6" sx={{ mt: 2 }}>
                Billing Address
            </Typography>
            <AddressFieldset prefix="billingAddress" form={form} />
            <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                <RhfTextField
                    control={form.control}
                    label="Phone Number"
                    name="phoneNumber"
                    required
                    InputProps={{
                        inputComponent: PhoneNumberInput,
                    }}
                    sx={{ flexBasis: "50%" }}
                    autoComplete="tel"
                />
                <RhfTextField
                    control={form.control}
                    label="Email Address"
                    name="emailAddress"
                    required
                    sx={{ flexBasis: "50%" }}
                    autoComplete="email"
                />
            </Stack>
        </Stack>
    );
};

export default BillingInformation;
