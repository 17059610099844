import { mapRawToEvent } from "@/mappers/event.js";
import type { Event, RawEvent } from "@/types/event.js";
import type { JsonApiDocument } from "@/types/json-api.js";
import { apiUrl } from "@/utils/api.js";
import { type UseQueryResult, keepPreviousData } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

type UseEventOptions = {
    excludeReservationId?: string;
    excludeLineItemId?: string;
};

export const useEventQuery = (
    eventId: string,
    options: UseEventOptions = {},
): UseQueryResult<Event> => {
    const encodedOptions = JSON.stringify(options);

    return useQuery({
        queryKey: ["events", eventId, encodedOptions],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/events/${eventId}`);

            if (options.excludeReservationId) {
                url.searchParams.set("excludeReservation", options.excludeReservationId);
            }

            if (options.excludeLineItemId) {
                url.searchParams.set("excludeLineItem", options.excludeLineItemId);
            }

            const response = await fetch(url.toString(), {
                signal,
            });

            if (!response.ok) {
                throw new Error(`Unable to fetch event with ID "${eventId}"`);
            }

            const raw = (await response.json()) as JsonApiDocument<RawEvent>;
            return mapRawToEvent(raw.data);
        },
        retry: false,
        placeholderData: keepPreviousData,
    });
};
