import type { Reservation } from "@/types/reservation.js";
import { Alert } from "@mui/material";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import type { CountdownRendererFn } from "react-countdown";
import Countdown from "react-countdown";
import { useSearchParams } from "react-router-dom";

type Props = {
    reservation: Reservation;
};

const getDate = (holdTime: number): Date => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + holdTime);
    return date;
};

const renderer: CountdownRendererFn = ({ minutes, seconds }) => {
    return `${minutes}:${seconds.toString().padStart(2, "0")} minutes`;
};

const HoldTimer = ({ reservation }: Props): ReactNode => {
    const [holdDate, setHoldDate] = useState(getDate(reservation.expiresIn));
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        setHoldDate(getDate(reservation.expiresIn));
    }, [reservation]);

    return (
        <Alert severity="warning">
            We are holding your seats for{" "}
            <Countdown
                date={holdDate}
                renderer={renderer}
                onComplete={() => {
                    setSearchParams({});
                }}
            />
            .
        </Alert>
    );
};

export default HoldTimer;
