import TableSelector from "@/components/SeatingChart/TableSelector.js";
import { seatWidth, tableSpacing } from "@/components/SeatingChart/utils.js";
import type { TableRow } from "@/seating-layouts/index.js";
import type { ReactNode } from "react";

type Props = {
    x: number;
    y: number;
    row: TableRow;
};

const TableRowSelector = ({ x, y, row }: Props): ReactNode => {
    let tableX = 0;

    return (
        <g transform={`translate(${x}, ${y})`}>
            {row.tables.map((table) => {
                const tableSelector = <TableSelector key={table} x={tableX} y={0} table={table} />;
                tableX += seatWidth * 2 + tableSpacing;

                return tableSelector;
            })}
        </g>
    );
};

export default TableRowSelector;
