import CourseClassItem from "@/pages/Checkout/Details/CourseClassItem.js";
import type { Cart, CourseClassLineItem } from "@/types/cart.js";
import { Divider, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import type { ReactNode } from "react";
import { Fragment } from "react";

type Props = {
    cart: Cart;
    items: CourseClassLineItem[];
};

const CourseClasses = ({ cart, items }: Props): ReactNode => {
    if (items.length === 0) {
        return <Typography sx={{ p: 2 }}>You have not added any classes to your cart.</Typography>;
    }

    return (
        <Stack sx={{ p: 2 }}>
            {items.map((item, index) => (
                <Fragment key={item.id}>
                    {index > 0 && <Divider />}

                    <CourseClassItem item={item} cart={cart} />
                </Fragment>
            ))}
        </Stack>
    );
};

export default CourseClasses;
