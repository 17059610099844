import { LoginRedirectError } from "@/hooks/useOidcFetch";
import { Container, LinearProgress, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import type { ReactNode } from "react";
import { useRouteError, useSearchParams } from "react-router-dom";

const ErrorPage = (): ReactNode => {
    const error = useRouteError();
    const [searchParams] = useSearchParams();

    if (error instanceof LoginRedirectError) {
        return <LinearProgress />;
    }

    const message = searchParams.get("message") ?? "An unknown error occurred.";

    return (
        <Container sx={{ py: 3 }}>
            <Typography variant="h5">An error occurred</Typography>
            <Typography sx={{ mb: 2 }}>{message}</Typography>

            <Typography>
                <Link href="https://www.oldtownschool.org">Return to Home</Link>
            </Typography>
        </Container>
    );
};

export default ErrorPage;
