import type { Block, SeatingLayout } from "./types.js";

const balcony: Block[] = [
    {
        verticalAlign: "top",
        sections: {
            left: {
                align: "center",
                rows: [
                    { type: "seats", name: "G", startNumber: 214, endNumber: 201 },
                    { type: "seats", name: "H", startNumber: 214, endNumber: 201 },
                    { type: "seats", name: "I", startNumber: 215, endNumber: 201 },
                ],
            },
            center: {
                align: "center",
                rows: [
                    { type: "seats", name: "G", startNumber: 15, endNumber: 1 },
                    { type: "seats", name: "H", startNumber: 18, endNumber: 1 },
                    { type: "seats", name: "I", startNumber: 15, endNumber: 1 },
                    { type: "seats", name: "J", startNumber: 14, endNumber: 1 },
                ],
            },
            right: {
                align: "center",
                rows: [
                    { type: "seats", name: "G", startNumber: 101, endNumber: 114 },
                    { type: "seats", name: "H", startNumber: 101, endNumber: 114 },
                    { type: "seats", name: "I", startNumber: 101, endNumber: 115 },
                ],
            },
        },
    },
];

const commonMain: Block[] = [
    {
        verticalAlign: "top",
        sections: {
            left: {
                align: "right",
                rows: [
                    { type: "seats", name: "A", startNumber: 208, endNumber: 201 },
                    { type: "seats", name: "B", startNumber: 210, endNumber: 201 },
                    { type: "seats", name: "C", startNumber: 212, endNumber: 201 },
                    { type: "seats", name: "D", startNumber: 214, endNumber: 201 },
                ],
            },
            center: {
                align: "center",
                rows: [
                    { type: "seats", name: "A", startNumber: 15, endNumber: 1 },
                    { type: "seats", name: "B", startNumber: 16, endNumber: 1 },
                    { type: "seats", name: "C", startNumber: 15, endNumber: 1 },
                    { type: "seats", name: "D", startNumber: 16, endNumber: 1 },
                    { type: "seats", name: "E", startNumber: 15, endNumber: 1 },
                    { type: "seats", name: "F", startNumber: 16, endNumber: 1 },
                ],
            },
            right: {
                align: "left",
                rows: [
                    { type: "seats", name: "A", startNumber: 101, endNumber: 108 },
                    { type: "seats", name: "B", startNumber: 101, endNumber: 110 },
                    { type: "seats", name: "C", startNumber: 101, endNumber: 112 },
                    { type: "seats", name: "D", startNumber: 101, endNumber: 114 },
                ],
            },
        },
    },
    {
        verticalAlign: "top",
        sections: {
            left: {
                align: "right",
                rows: [{ type: "tables", tables: ["BB", "AA", "Z", "Y"] }],
            },
            center: {
                align: "center",
                rows: [{ type: "tables", tables: ["X", "W", "V"] }],
            },
            right: {
                align: "left",
                rows: [{ type: "tables", tables: ["U", "T", "S", "R"] }],
            },
        },
    },
];

export const fullLayout: SeatingLayout = {
    balcony,
    main: [
        {
            verticalAlign: "bottom",
            sections: {
                left: {
                    align: "right",
                    rows: [{ type: "seats", name: "FC", startNumber: 206, endNumber: 201 }],
                },
                center: {
                    align: "center",
                    rows: [
                        { type: "seats", name: "FA", startNumber: 16, endNumber: 1 },
                        { type: "seats", name: "FB", startNumber: 15, endNumber: 1 },
                        { type: "seats", name: "FC", startNumber: 16, endNumber: 1 },
                    ],
                },
                right: {
                    align: "left",
                    rows: [{ type: "seats", name: "FC", startNumber: 101, endNumber: 106 }],
                },
            },
        },
        ...commonMain,
    ],
};

export const cabaretLayout: SeatingLayout = {
    balcony,
    main: [
        {
            verticalAlign: "top",
            sections: {
                left: {
                    align: "right",
                    rows: [{ type: "tables", tables: ["G"] }],
                },
                center: {
                    align: "center",
                    rows: [{ type: "tables", tables: ["F", "E", "D", "C", "B"] }],
                },
                right: {
                    align: "left",
                    rows: [{ type: "tables", tables: ["A"] }],
                },
            },
        },
        {
            verticalAlign: "top",
            sections: {
                left: {
                    align: "right",
                    rows: [{ type: "tables", tables: ["Q", "P"] }],
                },
                center: {
                    align: "center",
                    rows: [{ type: "tables", tables: ["O", "N", "M", "L", "K"] }],
                },
                right: {
                    align: "left",
                    rows: [{ type: "tables", tables: ["J", "H"] }],
                },
            },
        },
        ...commonMain,
    ],
};
