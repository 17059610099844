import { useApplyGiftCertificateMutation } from "@/mutations/cart.js";
import type { Cart } from "@/types/cart.js";
import { createMaskedInput } from "@/utils/masked-input.js";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, InputAdornment } from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const GiftCertificateInput = createMaskedInput({ mask: "****-****-****-****" });

const giftCertificateSchema = z.object({
    code: z.string().regex(/^[0-9a-zA-Z]{4}(?:-[0-9a-zA-Z]{4}){3}$/),
});

type GiftCertificateFormValues = z.infer<typeof giftCertificateSchema>;

type Props = {
    cart: Cart;
};

const ApplyGiftCertificate = ({ cart }: Props): ReactNode => {
    const { enqueueSnackbar } = useSnackbar();
    const applyGiftCertificateMutation = useApplyGiftCertificateMutation(cart.id);
    const form = useForm<GiftCertificateFormValues>({
        resolver: zodResolver(giftCertificateSchema),
    });

    const handleSubmit = (values: GiftCertificateFormValues) => {
        applyGiftCertificateMutation.mutate(values, {
            onSuccess: () => {
                form.reset();
                enqueueSnackbar("Gift certificate has been applied", { variant: "success" });
            },
            onError: (error) => {
                enqueueSnackbar(error.message, { variant: "error" });
            },
        });
    };

    return (
        <Box
            component="form"
            sx={{ flexGrow: 1 }}
            onSubmit={form.handleSubmit(handleSubmit)}
            noValidate
        >
            <RhfTextField
                control={form.control}
                label="Gift Certificate"
                name="code"
                InputProps={{
                    inputComponent: GiftCertificateInput,
                    endAdornment: (
                        <InputAdornment position="end">
                            <LoadingButton
                                disabled={!form.formState.isValid}
                                loading={applyGiftCertificateMutation.isPending}
                                type="submit"
                            >
                                Apply
                            </LoadingButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
            />
        </Box>
    );
};

export default ApplyGiftCertificate;
