import { getLayout } from "@/seating-layouts/index.js";
import type { ReservedEvent } from "@/types/event.js";
import type { Reservation } from "@/types/reservation.js";
import { Box, useTheme } from "@mui/material";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import FloorSelector from "./FloorSelector.js";
import type { SectionedSizes } from "./utils.js";
import {
    balconySeparatorHeight,
    calculateFloorSize,
    calculateLayoutSize,
    stageHeight,
} from "./utils.js";

type Context = {
    event: ReservedEvent;
    layoutSize: SectionedSizes;
    desiredTickets: number;
    reservation?: Reservation;
    interactive?: boolean;
    onSelect?: (numTotalSeats: number, numNewSeats: number) => void;
};

const SeatingChartContext = createContext<Context | null>(null);

export const useSeatingChartContext = (): Context => {
    const context = useContext(SeatingChartContext);

    if (!context) {
        throw new Error("useSeatingChartContext used outside of context");
    }

    return context;
};

export type SeatingChartProps = {
    event: ReservedEvent;
    desiredTickets: number;
    reservation?: Reservation;
    interactive?: boolean;
    onClick?: () => void;
    onSelect?: (numTotalSeats: number, numNewSeats: number) => void;
    applySize?: boolean;
};

const SeatingChart = ({
    event,
    desiredTickets,
    reservation,
    interactive,
    onClick,
    applySize,
    onSelect,
}: SeatingChartProps): ReactNode => {
    const layout = getLayout(event.layout);
    const layoutSize = calculateLayoutSize(layout);
    const mainSize = calculateFloorSize(layout.main);
    const theme = useTheme();
    const stageWidth = 500;

    return (
        <Box
            component="svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${layoutSize.total.width} ${layoutSize.total.height}`}
            width={applySize ? layoutSize.total.height : undefined}
            height={applySize ? layoutSize.total.height : undefined}
            sx={{
                display: "block",
                userSelect: "none",
                cursor: onClick ? "pointer" : undefined,
            }}
            onClick={onClick}
        >
            <defs>
                <g id="accessible-icon">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="4" r="2" />
                    {/* eslint-disable-next-line max-len */}
                    <path d="M19 13v-2c-1.54.02-3.09-.75-4.07-1.83l-1.29-1.43c-.17-.19-.38-.34-.61-.45-.01 0-.01-.01-.02-.01H13c-.35-.2-.75-.3-1.19-.26C10.76 7.11 10 8.04 10 9.09V15c0 1.1.9 2 2 2h5v5h2v-5.5c0-1.1-.9-2-2-2h-3v-3.45c1.29 1.07 3.25 1.94 5 1.95zm-6.17 5c-.41 1.16-1.52 2-2.83 2-1.66 0-3-1.34-3-3 0-1.31.84-2.41 2-2.83V12.1c-2.28.46-4 2.48-4 4.9 0 2.76 2.24 5 5 5 2.42 0 4.44-1.72 4.9-4h-2.07z" />
                </g>
            </defs>

            <SeatingChartContext.Provider
                value={{
                    event,
                    layoutSize,
                    interactive,
                    reservation,
                    desiredTickets,
                    onSelect,
                }}
            >
                <rect
                    x={layoutSize.total.width / 2 - stageWidth / 2}
                    y={0}
                    width={stageWidth}
                    height={40}
                    fill={theme.palette.grey["300"]}
                />
                <text
                    fontFamily={theme.typography.fontFamily}
                    textAnchor="middle"
                    fontSize={theme.typography.fontSize * 2}
                    x={layoutSize.total.width / 2}
                    y={theme.typography.fontSize * 2 + 2}
                >
                    Stage
                </text>

                <FloorSelector x={0} y={stageHeight} blocks={layout.main} />

                <path
                    d={`
                        M 0 ${stageHeight + mainSize.total.height + 24}
                        H ${layoutSize.total.width}
                    `}
                    strokeWidth={1}
                    stroke={theme.palette.divider}
                />
                <text
                    fontFamily={theme.typography.fontFamily}
                    textAnchor="middle"
                    fontSize={theme.typography.fontSize * 2}
                    x={layoutSize.total.width / 2}
                    y={stageHeight + mainSize.total.height + 32 + theme.typography.fontSize * 2 + 2}
                >
                    Balcony
                </text>

                <FloorSelector
                    x={0}
                    y={stageHeight + balconySeparatorHeight + mainSize.total.height}
                    blocks={layout.balcony}
                />
            </SeatingChartContext.Provider>
        </Box>
    );
};

export default SeatingChart;
