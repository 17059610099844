import { addressSchema } from "@/components/AddressFieldset/AddressFieldset.js";
import AddressFieldset from "@/components/AddressFieldset/index.js";
import Chatlio from "@/components/Chatlio";
import { creditCardSchema } from "@/components/CreditCardFieldset/CreditCardFieldset.js";
import CreditCardFieldset from "@/components/CreditCardFieldset/index.js";
import FieldError from "@/components/FieldError/index.js";
import useCart from "@/hooks/useCart.js";
import useDispatchAcceptJs, { AcceptJsError } from "@/hooks/useDispatchAcceptJs";
import { useSubmitCartMutation } from "@/mutations/cart";
import AddReservation from "@/pages/Checkout/AddReservation";
import { AddCourseClass } from "@/pages/Checkout/CourseClass";
import { AddGeneralAdmissionEvent } from "@/pages/Checkout/GeneralAdmissionEvent";
import { usePaymentProfilesQuery } from "@/queries/payment-profile.js";
import type { Cart } from "@/types/cart.js";
import { errorMap } from "@/utils/zod.js";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Alert,
    Box,
    Container,
    Divider,
    FormControlLabel,
    LinearProgress,
    Link,
    MenuItem,
    Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { RhfCheckbox, RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import type { CardData, DispatchDataResponse } from "react-acceptjs";
import type { CountdownRendererFn } from "react-countdown";
import Countdown from "react-countdown";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import ApplyGiftCertificate from "./ApplyGiftCertificate.js";
import ApplyPromoCode from "./ApplyPromoCode.js";
import type { DetailsTabValue } from "./Details/index.js";
import Details from "./Details/index.js";
import MembershipDonation from "./MembershipDonation.js";
import Summary from "./Summary/index.js";

const renderer: CountdownRendererFn = ({ minutes, seconds }) => {
    return `${minutes}:${seconds.toString().padStart(2, "0")} minutes`;
};

const baseCheckoutSchema = z.object({
    address: addressSchema,
    tacAccepted: z.boolean().refine((value) => value, "You must accept the terms and conditions"),
});

const paymentCheckoutSchema = baseCheckoutSchema.and(
    // @todo This can be simplified a lot once we have `z.switch`
    // @see https://github.com/colinhacks/zod/issues/2106
    z.preprocess(
        (value) => {
            if (
                typeof value === "object" &&
                value &&
                "paymentProfileId" in value &&
                value.paymentProfileId !== "create-new"
            ) {
                return { paymentProfileId: value.paymentProfileId };
            }

            return value;
        },
        z
            .object({
                paymentProfileId: z.string().regex(/^(?:create-new|\d+)$/),
                creditCard: creditCardSchema.optional(),
            })
            .superRefine((value, context) => {
                if (value.paymentProfileId !== "create-new") {
                    return;
                }

                const parseResult = creditCardSchema.safeParse(value.creditCard);

                if (parseResult.success) {
                    return;
                }

                for (const error of parseResult.error.errors) {
                    context.addIssue({ ...error, path: ["creditCard", ...error.path] });
                }
            }),
    ),
);

type CheckoutFormValues =
    | z.infer<typeof baseCheckoutSchema>
    | z.infer<typeof paymentCheckoutSchema>;

type CheckoutElementsProps = {
    cart: Cart;
};

const CheckoutElements = ({ cart }: CheckoutElementsProps): ReactNode => {
    const navigate = useNavigate();
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [detailsTab, setDetailsTab] = useState<DetailsTabValue>(() => {
        if (
            cart.lineItems.length === 0 ||
            cart.lineItems.some((item) => item.type === "course-class")
        ) {
            return "classes";
        }

        return "events";
    });
    const submitCartMutation = useSubmitCartMutation(cart.id);
    const paymentProfilesQuery = usePaymentProfilesQuery();
    const dispatchAcceptJs = useDispatchAcceptJs();

    const schema = cart.summary.total.amount > 0 ? paymentCheckoutSchema : baseCheckoutSchema;

    const form = useForm<CheckoutFormValues>({
        resolver: zodResolver(schema, { errorMap }),
        defaultValues: {
            tacAccepted: false,
            address: {
                countryCode: "US",
            },
            paymentProfileId: "create-new",
            creditCard: {
                cardNumber: "",
                cvv: "",
                expirationDate: "",
            },
        },
    });

    useEffect(() => {
        const defaultProfile = paymentProfilesQuery.data?.find((profile) => profile.isDefault);

        if (defaultProfile) {
            form.setValue("paymentProfileId", defaultProfile.id);
        }
    }, [paymentProfilesQuery.data, form.setValue]);

    const handleSubmit = async (values: CheckoutFormValues) => {
        let opaqueAuthorizeNetResponse: DispatchDataResponse | null = null;
        let paymentProfileId: string | null = null;

        if ("creditCard" in values && values.creditCard && cart.summary.total.amount > 0) {
            const creditCard = values.creditCard;

            const [expirationMonth, expirationYear] = creditCard.expirationDate.split("/");
            const cardData: CardData = {
                cardNumber: creditCard.cardNumber,
                month: expirationMonth,
                year: expirationYear,
                cardCode: creditCard.cvv,
                fullName: `${values.address.firstName} ${values.address.lastName}`,
                zip: values.address.zipCode,
            };

            try {
                opaqueAuthorizeNetResponse = await dispatchAcceptJs(cardData);
            } catch (error) {
                if (error instanceof AcceptJsError) {
                    form.setError("creditCard.cardNumber", {
                        type: "custom",
                        message: error.message,
                    });
                }

                return;
            }
        } else if (
            "paymentProfileId" in values &&
            cart.summary.total.amount > 0 &&
            values.paymentProfileId !== "create-new"
        ) {
            paymentProfileId = values.paymentProfileId;
        }

        try {
            await submitCartMutation.mutateAsync({
                address: values.address,
                opaqueAuthorizeNetData: opaqueAuthorizeNetResponse
                    ? {
                          descriptor: opaqueAuthorizeNetResponse.opaqueData.dataDescriptor,
                          value: opaqueAuthorizeNetResponse.opaqueData.dataValue,
                      }
                    : null,
                paymentProfileId,
            });
        } catch (error) {
            // Noop, mutation errors are displayed inline.
            return;
        }

        navigate(`/checkout/success/${cart.id}`, { replace: true });
    };

    return (
        <Container sx={{ py: 3 }}>
            <Stack spacing={2}>
                <Alert severity="warning">
                    Please finish your checkout within the next{" "}
                    <Countdown
                        date={cart.expiresAt}
                        renderer={renderer}
                        onComplete={() => {
                            navigate({
                                pathname: "/error",
                                search: `?message=${encodeURIComponent("Your cart expired.")}`,
                            });
                        }}
                    />
                    .
                </Alert>

                <Details
                    cart={cart}
                    activeTab={detailsTab}
                    setActiveTab={setDetailsTab}
                    open={detailsOpen}
                    setOpen={setDetailsOpen}
                />

                <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                    <Summary
                        cart={cart}
                        onItemsEdit={(tabValue) => {
                            setDetailsTab(tabValue);
                            setDetailsOpen(true);
                        }}
                    />

                    <MembershipDonation cart={cart} />
                </Stack>

                <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                    <ApplyGiftCertificate cart={cart} />
                    <ApplyPromoCode cart={cart} />
                </Stack>

                <Stack
                    component="form"
                    onSubmit={form.handleSubmit(handleSubmit)}
                    noValidate
                    spacing={2}
                >
                    <Typography variant="h6" sx={{ pt: 2 }}>
                        Purchaser information
                    </Typography>
                    <AddressFieldset prefix="address" form={form} />

                    {cart.summary.total.amount > 0 && (
                        <>
                            <Typography variant="h6" sx={{ pt: 2 }}>
                                Payment information
                            </Typography>

                            <RhfTextField
                                label="Payment Profile"
                                required
                                select
                                control={form.control}
                                name="paymentProfileId"
                            >
                                <MenuItem value="create-new">Create new payment profile</MenuItem>

                                {paymentProfilesQuery.data?.length && [
                                    <Divider key="divider" />,
                                    ...paymentProfilesQuery.data.map((profile) => (
                                        <MenuItem key={profile.id} value={profile.id}>
                                            {profile.cardNumber} ({profile.cardType})
                                        </MenuItem>
                                    )),
                                ]}
                            </RhfTextField>

                            <Controller
                                control={form.control}
                                name="paymentProfileId"
                                render={({ field: { value } }) => (
                                    <CreditCardFieldset
                                        prefix="creditCard"
                                        form={form}
                                        disabled={value !== "create-new"}
                                    />
                                )}
                            />
                        </>
                    )}

                    <Box sx={{ pt: 2 }}>
                        <FormControlLabel
                            control={<RhfCheckbox control={form.control} name="tacAccepted" />}
                            label={
                                <>
                                    I have read and understand the{" "}
                                    <Link
                                        href="https://www.oldtownschool.org/aboutus/policy/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        terms and conditions
                                    </Link>
                                </>
                            }
                        />
                        <FieldError control={form.control} name="tacAccepted" />
                    </Box>

                    {submitCartMutation.isError && (
                        <Alert severity="error">{submitCartMutation.error.message}</Alert>
                    )}

                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={form.formState.isSubmitting}
                    >
                        <span>Purchase my order</span>
                    </LoadingButton>
                </Stack>
            </Stack>

            <AddGeneralAdmissionEvent cart={cart} />
            <AddCourseClass cart={cart} />
            <AddReservation cart={cart} />
            <Chatlio />
        </Container>
    );
};

const Checkout = (): ReactNode => {
    const { cart } = useCart(true);

    if (!cart) {
        return <LinearProgress />;
    }

    return <CheckoutElements cart={cart} />;
};

export default Checkout;
