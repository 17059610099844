import { mapRawToCourseClass } from "@/mappers/course-class.js";
import { mapRawToEvent } from "@/mappers/event.js";
import { mapRawToReservation } from "@/mappers/reservation.js";
import { mapRawToStudent } from "@/mappers/student.js";
import type {
    Cart,
    CourseClassLineItem,
    GeneralAdmissionEventLineItem,
    LineItem,
    RawCart,
    RawLineItem,
    ReservedEventLineItem,
} from "@/types/cart.js";
import type { GeneralAdmissionEvent, ReservedEvent } from "@/types/event.js";

const mapRawToCartLineItem = (raw: RawLineItem): LineItem => {
    switch (raw.type) {
        case "reserved-event":
            return {
                ...raw,
                reservation: mapRawToReservation(raw.reservation),
                event: mapRawToEvent(raw.event) as ReservedEvent,
            } satisfies ReservedEventLineItem;

        case "general-admission-event":
            return {
                ...raw,
                event: mapRawToEvent(raw.event) as GeneralAdmissionEvent,
            } satisfies GeneralAdmissionEventLineItem;

        case "course-class":
            return {
                ...raw,
                courseClass: mapRawToCourseClass(raw.courseClass),
                student: raw.student ? mapRawToStudent(raw.student) : null,
            } satisfies CourseClassLineItem;
    }
};

export const mapRawToCart = (raw: RawCart): Cart => {
    const expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + raw.expiresIn);

    return {
        ...raw,
        lineItems: raw.lineItems.map(mapRawToCartLineItem),
        expiresAt,
    };
};
