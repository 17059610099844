import { useSeatingChartContext } from "@/components/SeatingChart/SeatingChart.js";
import type {
    HorizontalAlign,
    Section,
    SectionName,
    VerticalAlign,
} from "@/seating-layouts/index.js";
import type { ReactNode } from "react";
import SeatRowSelector from "./SeatRowSelector.js";
import TableRowSelector from "./TableRowSelector.js";
import type { SectionedSizes, Size } from "./utils.js";
import {
    calculateSeatRowSize,
    calculateSectionSize,
    calculateTableRowSize,
    rowSpacing,
} from "./utils.js";

const calculateVerticalAlignmentOffset = (
    align: VerticalAlign,
    section: Section,
    blockHeight: number,
): number => {
    if (align === "top") {
        return 0;
    }

    const sectionSize = calculateSectionSize(section);

    switch (align) {
        case "center":
            return blockHeight / 2 - sectionSize.height / 2;

        case "bottom":
            return blockHeight - sectionSize.height;
    }
};

const calculateHorizontalAlignmentOffset = (
    align: HorizontalAlign,
    width: number,
    layoutSize: SectionedSizes,
    sectionName: SectionName,
): number => {
    if (align === "left") {
        return 0;
    }

    const sectionWidth = layoutSize[`${sectionName}Width`];

    switch (align) {
        case "center":
            return sectionWidth / 2 - width / 2;

        case "right":
            return sectionWidth - width;
    }
};

type Props = {
    x: number;
    y: number;
    section: Section;
    sectionName: SectionName;
    verticalAlign: VerticalAlign;
    blockHeight: number;
};

const SectionSelector = ({
    x,
    y,
    section,
    sectionName,
    verticalAlign,
    blockHeight,
}: Props): ReactNode => {
    const { layoutSize } = useSeatingChartContext();
    let rowY = calculateVerticalAlignmentOffset(verticalAlign, section, blockHeight);

    return (
        <g transform={`translate(${x}, ${y})`}>
            {section.rows.map((row, index) => {
                let rowSelector: ReactNode;
                let rowSize: Size;

                switch (row.type) {
                    case "seats": {
                        rowSize = calculateSeatRowSize(row);
                        rowSelector = (
                            <SeatRowSelector
                                // biome-ignore lint/suspicious/noArrayIndexKey: no ID available
                                key={index}
                                x={calculateHorizontalAlignmentOffset(
                                    section.align,
                                    rowSize.width,
                                    layoutSize,
                                    sectionName,
                                )}
                                y={rowY}
                                row={row}
                            />
                        );
                        break;
                    }

                    case "tables": {
                        rowSize = calculateTableRowSize(row);
                        rowSelector = (
                            <TableRowSelector
                                // biome-ignore lint/suspicious/noArrayIndexKey: no ID available
                                key={index}
                                x={calculateHorizontalAlignmentOffset(
                                    section.align,
                                    rowSize.width,
                                    layoutSize,
                                    sectionName,
                                )}
                                y={rowY}
                                row={row}
                            />
                        );
                        break;
                    }
                }

                rowY += rowSize.height + rowSpacing;

                return rowSelector;
            })}
        </g>
    );
};

export default SectionSelector;
