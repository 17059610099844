import AddressFieldset from "@/components/AddressFieldset/AddressFieldset";
import PhoneNumberInput from "@/components/PhoneNumberInput/PhoneNumberInput";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import type { UseFormReturn } from "react-hook-form";
import type { DonationFormValues } from "./Donate.js";

type Props = {
    form: UseFormReturn<DonationFormValues>;
};

const PersonalInformation = ({ form }: Props): ReactNode => {
    return (
        <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography variant="h6">Personal Information</Typography>
            <AddressFieldset prefix="personalAddress" form={form} />
            <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                <RhfTextField
                    control={form.control}
                    label="Phone Number"
                    name="phoneNumber"
                    required
                    InputProps={{
                        inputComponent: PhoneNumberInput,
                    }}
                    sx={{ flexBasis: "50%" }}
                    autoComplete="tel"
                />
                <RhfTextField
                    control={form.control}
                    label="Email Address"
                    name="emailAddress"
                    required
                    sx={{ flexBasis: "50%" }}
                    autoComplete="email"
                />
            </Stack>
        </Stack>
    );
};

export default PersonalInformation;
