import type { Cart } from "@/types/cart.js";
import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";
import CourseClassDialog from "./CourseClassDialog";

type Props = {
    cart: Cart;
};

const AddCourseClass = ({ cart }: Props): ReactNode => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [courseClassId, setCourseClassId] = useState<string | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const addCourseClassId = searchParams.get("courseClassId");

    useEffect(() => {
        if (addCourseClassId) {
            setCourseClassId(addCourseClassId);
            setDialogOpen(true);
        }
    }, [addCourseClassId]);

    if (!courseClassId) {
        return null;
    }

    return (
        <CourseClassDialog
            open={dialogOpen}
            onClose={() => {
                const referrer = searchParams.get("referrer");

                if (referrer && cart.lineItems.length === 0) {
                    window.location.href = referrer;
                    return;
                }

                setDialogOpen(false);
                setSearchParams({}, { replace: true });
            }}
            courseClassId={courseClassId}
            cart={cart}
        />
    );
};

export default AddCourseClass;
