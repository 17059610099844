import { LoginRedirectError } from "@/hooks/useOidcFetch";
import { useCreateCartMutation } from "@/mutations/cart.js";
import { CartExpiredError, CartFetchError, useCartQuery } from "@/queries/cart.js";
import type { Cart } from "@/types/cart.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export type UseCartReturn = {
    isLoading: boolean;
    cart: Cart | undefined;
};

export default (createIfNotExists = false): UseCartReturn => {
    const { isAuthenticated } = useAuth0();
    const [cartId, setCartId] = useState<string | null>(window.localStorage.getItem("cartId"));
    const createCartMutation = useCreateCartMutation();
    const cartQuery = useCartQuery(
        cartId ?? "",
        cartId !== null && (isAuthenticated || createIfNotExists),
    );
    const navigate = useNavigate();
    const mutating = useRef(false);

    useEffect(() => {
        if (
            cartQuery.error instanceof CartExpiredError ||
            cartQuery.error instanceof CartFetchError
        ) {
            setCartId(null);
            window.localStorage.removeItem("cartId");
        }
    }, [cartQuery.error]);

    // biome-ignore lint/correctness/useExhaustiveDependencies(createCartMutation.isPending): Needs to update when loading state changes
    useEffect(() => {
        if (mutating.current || cartId || !createIfNotExists || createCartMutation.isError) {
            return;
        }

        mutating.current = true;

        const handle = window.requestAnimationFrame(() => {
            createCartMutation.mutate(undefined, {
                onSuccess: (cart) => {
                    mutating.current = false;

                    setCartId(cart.id);
                    window.localStorage.setItem("cartId", cart.id);
                },
                onError: (error) => {
                    mutating.current = false;

                    if (error instanceof LoginRedirectError) {
                        return;
                    }

                    navigate("/error");
                },
            });
        });

        return () => {
            window.cancelAnimationFrame(handle);
            mutating.current = false;
        };
    }, [
        cartId,
        createIfNotExists,
        createCartMutation.isPending,
        createCartMutation.isError,
        createCartMutation.mutate,
        navigate,
    ]);

    return {
        isLoading: cartQuery.isLoading,
        cart: cartQuery.data,
    };
};
